import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/PreviewSideMenu.css';

const SideMenuPreview = ({ items, backgroundImage }) => {
    const previewWidth = 320 * 2 / 3; // 2/3 of the smallest iOS device width (320px)
    console.log(backgroundImage);

    return (
        <div className="device-frame">
            <div className="side-menu-preview" style={{ width: previewWidth }}>
                <div
                    className="side-menu"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: '150% 100%', // Scale background to be larger
                        backgroundPosition: 'left center', // Position background to show the left part
                    }}
                >
                    {items.map((item, index) => {
                        if (item.buttonType === "isSectionTitle") {
                            return (
                                <div key={item.id} className="section-header">
                                    {item.header}
                                </div>
                            );
                        }
                        return (
                            <div key={index} className="side-menu-item">
                                <span>{item.header}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

SideMenuPreview.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            icon: PropTypes.string,
            buttonType: PropTypes.string
        })
    ).isRequired,
    backgroundImage: PropTypes.string.isRequired,
};

export default SideMenuPreview;
