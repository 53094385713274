import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {base} from '../../base';
import '../../styles/css/AdminMain.css';

class SetUpPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goalValue: 0,
            assistValue: 0,
            goodPlayValue: 0,
            checkInValue: 0,
            checkInDistance: 0,
            loading: true
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.pointRulesRef = base.syncState(`pointRules`, {
            context: this,
            state: 'pointRules',
        });
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.pointRulesRef);
    }

    handleChange (evt) {
        let pointRulesCopy = this.state.pointRules;
        let target = evt.target;
        pointRulesCopy[target.name] = target.type === 'checkbox' ? target.checked : target.value;
        pointRulesCopy[target.name] = parseInt(target.value)
        this.setState({pointRules: pointRulesCopy});
    }

    render() {
        const {pointRules={}} = this.state;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out" style={{width:'45%', float:'left'}}>
                <div className="admin-form-box">
                    <div className="form-group">
                      <label htmlFor="perGoal">Points Per Goal</label>
                      <input type="number" id="perGoal" name="perGoal" className="form-control" value={pointRules.perGoal} onChange={this.handleChange} placeholder="1"/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="perAssist">Points Per Assist</label>
                      <input id="perAssist" name="perAssist" type="number" className="form-control" value={pointRules.perAssist} onChange={this.handleChange} placeholder="2"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="perGoodPlay">Points Per Good Play</label>
                        <input id="perGoodPlay" name="perGoodPlay" type="number" className="form-control" value={pointRules.perGoodPlay} onChange={this.handleChange} placeholder="3"/>
                    </div>
                      <div className="form-group">
                          <label htmlFor="perCheckin">Points Per Check In</label>
                          <input id="perCheckin" name="perCheckin" type="number" className="form-control" value={pointRules.perCheckin} onChange={this.handleChange} placeholder="10"/>
                      </div>
                    <div className="form-group">
                        <label htmlFor="distance">Distance To Check In (Meters)</label>
                        <input id="distance" name="distance" type="number" className="form-control" value={pointRules.distance} onChange={this.handleChange} placeholder="100"/>
                    </div>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpPoints;
