import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import '../../styles/css/AdminMain.css';
import {ErrorMessage, WarningMessage} from '../utils/HelpfulFunction';
import BasicDropzone from '../utils/Dropzone';

class SetUpRewards extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            editingTicket: false,
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            rewardName: "",
            rewardsList: [],
            rewardLink: "",
            rewardDescription: "",
            rewardAmount: 0,
            rewardCost: 0,
            rewardCodes: "",
            editTicketId: "",
            editTicketImage: '',
            editTicketSpinImage: '',
            imageUrlPreview: {},
            loading:true,
            codesArray: "",
            files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.getRewards();
    }

    getRewards(){
        const urlToGet = process.env.REACT_APP_REWARD_URL + 'coupons';
        fetch(urlToGet)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    loading: false,
                    rewardsList: data.data
                })
            })
            .catch(e => {
                console.log("ERROR", e)
                this.setState({loading: false})
            })
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        ErrorMessage.fire({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type'
        })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
          if(res.error){
              ErrorMessage.fire({
                  title: 'Image cannot be uploaded',
                  text: res.error,
              })
          } else if(res.imageUrl) {
              const nameToUpdate = myArgument + "Preview"
              const fileToUpdate = files[0]
              this.setState({
                  [myArgument]: res.imageUrl,
                  [nameToUpdate]: ({
                      fileToUpdate,
                      preview: URL.createObjectURL(fileToUpdate)
                  })
              });
          } else {
              ErrorMessage.fire({
                  title: 'Image cannot be uploaded',
                  text: 'Something went wrong, please re-upload your image and try again!'
              })
          }
      })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.imageUrlPreview.preview);
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    detectHowManyCodesEntered(codesArray){
        if(!codesArray){
            return [];
        }
        codesArray = codesArray.trim();
        const splitOnLineBreaks = codesArray.split("\n");
        let splitOnWhiteSpace = codesArray.split(/\s+/);
        let splitOnCommas = codesArray.split(",");
        let splitArray = splitOnLineBreaks;
        if(splitOnWhiteSpace.length === splitOnCommas.length){
            splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
            splitOnCommas = codesArray.replace(/\s/g,'').split(",");
        }
        if(splitArray.length < splitOnWhiteSpace.length){
            splitArray = splitOnWhiteSpace
        }
        if(splitArray.length < splitOnCommas.length){
            splitArray = splitOnCommas
        }
        return splitArray
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.loading){
            return
        }

        const rewardName = this.state.rewardName || "";
        if(rewardName.length > 50){
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Reward name cannot be longer than 50 characters!',
            })
            return;
        }
        const rewardLink = this.state.rewardLink || "";
        const rewardDescription = this.state.rewardDescription || "";
        const rewardCost = parseInt(this.state.rewardCost);
        const rewardAmount = parseInt(this.state.rewardAmount);
        const imageUrl = this.state.imageUrl || this.state.editTicketImage || "";
        let codesArray = this.state.codesArray || "";
        codesArray = codesArray.trim();
        if(codesArray){
            codesArray = this.detectHowManyCodesEntered(codesArray);
            if(codesArray.length !== rewardAmount){
                ErrorMessage.fire({
                    title: 'Input Error',
                    text: 'Number of codes entered must equal the number of coupons available'
                })
                return;
            }
        }
        if(!rewardName || !rewardDescription || !rewardLink || !imageUrl){
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again'
            })
            return;
        }
        if(typeof rewardCost !== "number" || rewardCost < 0 || rewardAmount < 0){
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: '"Prize Points Value" and "Prize Amount" must be filled in and a number'
            })
            return;
        }
        let rewardToSave = {};
        let urlToGet = process.env.REACT_APP_REWARD_URL + 'coupons';
        let method = "POST";
        if(this.state.editingTicket && this.state.rewardToEdit){
            rewardToSave = this.state.rewardToEdit;
            urlToGet = urlToGet + "/" + rewardToSave.id;
            method = "PUT"
        }

        rewardToSave['name'] = rewardName;
        rewardToSave['points'] = rewardCost;
        rewardToSave['number_available'] = rewardAmount;
        rewardToSave['image_url'] = imageUrl;
        rewardToSave['connecting_link'] = rewardLink;
        rewardToSave['description'] = rewardDescription;
        rewardToSave['unique_codes'] = codesArray || [];
        rewardToSave['pdf_url'] = rewardToSave.pdf_url || "";
        rewardToSave['ad_image'] = rewardToSave.ad_image || "";
        rewardToSave['image_url_click_link'] = rewardToSave.image_url_click_link || "";
        rewardToSave['ad_image_click_link'] = rewardToSave.ad_image_click_link || "";
        this.setState({loading: true});
        fetch(urlToGet, {method: method, body: JSON.stringify(rewardToSave), headers:{'Content-Type': 'application/json'}})
            .then(response => response.json())
            .then(data => {
                this.setState({loading: false})
                if(!data.error){
                    this.setState({
                        modal: false,
                        editingTicket: false,
                        rewardToEdit: null
                    })
                    this.getRewards();
                } else {
                    ErrorMessage.fire({
                        title: 'Error!',
                        text: 'Something went wrong, please check your internet connection and try again'
                    })
                }
            })
            .catch(e => {
                console.log("ERROR", e)
                ErrorMessage.fire({
                    title: 'Error!',
                    text: 'Something went wrong, please check your internet connection and try again'
                })
                this.setState({loading: false})
            })
    }

    editTicket(event){
      event.preventDefault();
      const array = this.state.rewardsList;
      const reward = array[event.target.value];
      let codesArray = "";
      if(reward.unique_codes && reward.unique_codes.length > 0){
          ErrorMessage.fire({
              title: 'Sorry',
              text: 'At this time we do not allow editing of rewards with codes'
          })
          return;
          // codesArray = reward.unique_codes.join(",")
      }
      this.setState({
          modal: true,
          rewardName: reward.name,
          rewardAmount: reward.number_available,
          rewardCost: reward.points,
          rewardLink: reward.connecting_link,
          rewardDescription: reward.description,
          editTicketImage:reward.image_url,
          rewardToEdit: reward,
          codesArray: codesArray,
          editingTicket: true
      });
    }

    async deleteTicket(e){
      e.preventDefault();
      const array = this.state.rewardsList;
      const index = array[e.target.value];
      const response = await WarningMessage.fire({
          title: 'Delete Prize?',
          text: 'Are you sure you want to do this?  You will no longer be able to use this prize in any new games',
          confirmButtonText: 'Delete'
      });
      if(response.value){
          this.setState({loading: true})
          const urlToGet = process.env.REACT_APP_REWARD_URL + 'coupons/' + index.id;
          fetch(urlToGet, {method: "DELETE"})
              .then(response => response.json())
              .then(data => {
                  this.getRewards();
              })
              .catch(e => {
                  console.log("ERROR", e)
                  this.setState({loading: false})
              })
      }
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        rewardName: "",
        rewardAmount: 0,
        rewardCost: 0,
        rewardLink: "",
        rewardDescription: "",
        editTicketImage:null,
        rewardToEdit: null,
        codesArray: "",
        editingTicket: false,
        imageUrlPreview: {}
      });
    }

    render() {
        const winningTicketList = this.state.rewardsList;
        const imageUrlPreview = this.state.imageUrlPreview;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Prizes</p>
                    <p className="admin-subheader-text">These are prizes fans will receive when they win</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Prize</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        winningTicketList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.name}</p>
                                <div className="row" style={{fontSize: 12}}>
                                    <div className="col-md-6">
                                        <p>
                                            Amount: {item.number_available}
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <p>
                                            Point Value: {item.points}
                                        </p>
                                    </div>
                                </div>
                                <div style={{margin: 10}}>
                                  <img width="80%" height="auto" src={item.image_url} alt=""/>
                                </div>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>{this.state.editingTicket ? "Edit" : "Add"} Prize</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group">
                            <label htmlFor="rewardName">Prize Name</label>
                            <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Sweet Prize Name"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="rewardAmount">Prize Amount</label>
                            <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="10"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="rewardCost">Prize Point Value</label>
                            <input id="rewardCost" name="rewardCost" type="number" className="form-control" value={this.state.rewardCost} onChange={this.handleChange} placeholder="10"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="rewardLink">Prize Link</label>
                          <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="rewardDescription">Prize Description: </label>
                            <input id="rewardDescription" name="rewardDescription" type="text" className="form-control" value={this.state.rewardDescription} onChange={this.handleChange} placeholder="My Sweet Prize Description"/>
                        </div>
                        {!this.state.editingTicket &&
                            <div className="form-group">
                                <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                            </div>
                        }
                        <div className="form-group" align="center">
                          <label htmlFor="imageUrl" style={{width:'100%'}}>
                              Prize Email Image
                              <span style={{cursor:"pointer", display: imageUrlPreview.preview || this.state.editTicketImage?"":"none"}} onClick={()=>this.setState({editTicketImage: null, fileUploaded:false, imageUrlPreview: {}, imageUrl:null})}>
                              ❌
                          </span>
                          </label>
                          <img src={this.state.editTicketImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                          <BasicDropzone
                              onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "imageUrl")}
                              preview={imageUrlPreview.preview}
                          />
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpRewards
