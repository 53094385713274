import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Input, Modal, ModalBody, ModalHeader, Table, Button } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { appDatabasePrimaryFunctions, base } from '../../base';
import '../../styles/css/AdminMain.css';
import '../../styles/css/SetUpCarouselCss.css';
import { ErrorMessage, isObjectEmpty, Toast, WarningMessage } from '../utils/HelpfulFunction';
import BasicDropzone from '../utils/Dropzone';

let apiUrl = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/`;
if(process.env.NODE_ENV === "development"){
    apiUrl = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/`;
}

class SetUpCarousel extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            itemsList: [],
            editingItem: false,
            itemToEdit: null,
            imageUrl: null,
            modal: false,
            rssModal: false,
            selectedFields: { title: "", link: "", image: "" },
            availableFields: {},
            itemName: "",
            itemLink: "",
            editItemId: "",
            editImage: '',
            imageUrlPreview: {},
            loading: true,
            files: [],
            rssFeedData: {},
            rssFeedLink: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleRssModal = this.toggleRssModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRssFeedLinkChange = this.handleRssFeedLinkChange.bind(this);
        this.loadRssFeedData = this.loadRssFeedData.bind(this);
        this.populateValuesFromRssFeed = this.populateValuesFromRssFeed.bind(this);
        this.handleFieldSelection = this.handleFieldSelection.bind(this);
        this.handleRssSubmit = this.handleRssSubmit.bind(this);
    }

    async componentDidMount() {
        this.teamPopulatedImageListRef = base.bindToState(`teamPopulatedImage`, {
            context: this,
            state: 'itemsList',
            query: {
                orderByChild: 'order'
            },
            asArray: true,
            then() {
                this.setState({ loading: false })
            },
            catch(e){
                this.setState({ loading: false })
            }
        });
        const carouselRssFeedsRef = await appDatabasePrimaryFunctions.ref('rssFeeds').child('carousel').once('value');
        if(carouselRssFeedsRef.exists()){
            this.setState({
                rssFeedLink: carouselRssFeedsRef.val().link,
                selectedFields: carouselRssFeedsRef.val().pathToValues
            });
        }
        const getAdminToken = await this.props.userData.getIdToken();
        this.setState({
            token: getAdminToken
        })
    }

    onDrop(files, rejected, myArgument) {
        if (rejected.length > 0) {
            ErrorMessage.fire({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type'
            });
            return;
        }
        this.setState({ loading: true });

        this.UploadImage.upload_file(files[0]).then(res => {
            console.log(res);
            this.setState({ loading: false });
            if (res.error) {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                });
            } else if (res.imageUrl) {
                const nameToUpdate = myArgument + "Preview";
                const fileToUpdate = files[0];
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: 'Something went wrong, please re-upload your image and try again!'
                });
            }
        });
    }

    componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(this.state.imageUrlPreview.preview);
        base.removeBinding(this.teamPopulatedImageListRef);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleRssFeedLinkChange(event) {
        const rssFeedLink = event.target.value;
        await appDatabasePrimaryFunctions.ref('rssFeeds').child('carousel').update({link: rssFeedLink});
        this.setState({ rssFeedLink });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        const name = this.state.itemName || "";
        const itemLink = this.state.itemLink || "";
        const itemsList = this.state.itemsList || [];
        if (!name || name.length > 50) {
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Name cannot be longer than 50 characters or be left blank!',
            });
            return;
        }
        const image = this.state.imageUrl || this.state.editImage || "";
        const vm = this;
        if (!itemLink || !image) {
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again'
            });
            return;
        }
        const itemToSave = {};
        itemToSave['header'] = name;
        itemToSave['link'] = itemLink;
        itemToSave['image'] = image;
        itemToSave['order'] = (this.state.itemToEdit && this.state.itemToEdit.order) || itemsList.length + 1;
        this.setState({ loading: true });
        itemToSave['id'] = (this.state.itemToEdit && this.state.itemToEdit.key) || appDatabasePrimaryFunctions.ref().push().key;
        base.post(`teamPopulatedImage/${itemToSave['id']}`, {
            data: itemToSave,
            then(err){
                if(!err){
                    Toast.fire({
                        title: vm.state.itemToEdit ? 'Edited Successfully!' : 'Created Successfully!'
                    });
                    vm.setState({
                        fileEmailImage: null,
                        itemName: "",
                        emailImage: null,
                        itemLink: "",
                        itemToEdit: null,
                        imageUrlPreview: {},
                        editingItem: false,
                        modal: false,
                        loading: false
                    });
                } else {
                    vm.setState({ loading: false });
                    ErrorMessage.fire({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in'
                    });
                    console.log(err);
                }
            }
        });
    }

    editItem(event) {
        event.preventDefault();
        const array = this.state.itemsList;
        const item = array[event.target.value];
        this.setState({
            modal: true,
            itemName: item.header,
            itemLink: item.link,
            editImage: item.image,
            itemToEdit: item,
            editingItem: true
        });
    }

    async deleteItem(e) {
        e.preventDefault();
        const array = this.state.itemsList;
        const index = array[e.target.value];
        const response = await WarningMessage.fire({
            title: 'Delete?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this anymore',
            confirmButtonText: 'Delete'
        });
        if (response.value) {
            base.remove('teamPopulatedImage/' + index.key, function (err) {
                if (err) {
                    ErrorMessage.fire({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                    });
                }
            });
        }
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            itemName: "",
            itemLink: "",
            editImage: "",
            itemToEdit: null,
            editingItem: false
        });
    }

    toggleRssModal() {
        this.setState({
            rssModal: !this.state.rssModal
        });
    }

    async loadRssFeedData() {
        const { rssFeedLink } = this.state;

        if (!rssFeedLink) {
            ErrorMessage.fire({
                title: 'No RSS Feed Link!',
                text: 'Please provide a valid RSS feed link.'
            });
            return;
        }

        try {
            this.setState({loading: true});
            const response = await fetch(apiUrl + "getRssFeed", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${this.state.token}`
                }
            });
            if (!response.ok) {
                this.setState({loading: false});
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const feed = await response.json();
            const firstItem = feed.items[0];
            const availableFields = this.extractAvailableFields(firstItem);
            this.setState({ availableFields, rssFeedData: firstItem, loading: false });
            this.toggleRssModal();
        } catch (error) {
            ErrorMessage.fire({
                title: 'Error loading RSS Feed',
                text: error.message
            });
        }
    }

    async populateValuesFromRssFeed(){
        const selectedFields = this.state.selectedFields || {};
        if(!selectedFields.title || !selectedFields.link || !selectedFields.image || !this.state.rssFeedLink){
            return ErrorMessage.fire({
                title: 'Hold On!',
                text: 'First select what fields you want us to pull data from by clicking the "RSS Values" button.'
            });
        }
        try {
            this.setState({loading: true});
            const response = await fetch(apiUrl + "manuallyPopulateCarouselFromRssFeed", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${this.state.token}`
                }
            });
            if (!response.ok) {
                this.setState({loading: false});
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseJson = await response.json();
            this.setState({ loading: false });
            if(responseJson.success){
                Toast.fire({
                    title: 'Success!',
                    text: 'RSS feed has been synced',
                });
            } else {
                ErrorMessage.fire({
                    title: 'Error Populating RSS Feed',
                    text: error.message
                });
            }
        } catch (error) {
            ErrorMessage.fire({
                title: 'Error Populating RSS Feed',
                text: error.message
            });
        }
    }

    extractAvailableFields(item, prefix = '') {
        const fields = {};
        for (const key in item) {
            if (typeof item[key] === 'object' && item[key] !== null) {
                const nestedFields = this.extractAvailableFields(item[key], prefix + key + '.');
                for (const nestedKey in nestedFields) {
                    fields[nestedKey] = null;
                }
            } else {
                fields[prefix + key] = null;
            }
        }
        return fields;
    }

    handleFieldSelection(event, field) {
        const { selectedFields } = this.state;
        selectedFields[field] = event.target.value;
        this.setState({ selectedFields });
    }

    async handleRssSubmit() {
        const { selectedFields } = this.state;

        if (!selectedFields.title || !selectedFields.link || !selectedFields.image) {
            ErrorMessage.fire({
                title: 'All Fields Required!',
                text: 'Please select fields for title, link, and image URL.'
            });
            return;
        }

        await appDatabasePrimaryFunctions.ref('rssFeeds').child('carousel').update({pathToValues: selectedFields});

        this.setState({
            rssModal: false
        });

        Toast.fire({
            title: 'RSS Feed Item Loaded!',
            text: 'You can now save the item.',
        });
    }

    renderFieldOptions(fields, prefix = '') {
        if (!fields || typeof fields !== 'object') {
            return null;
        }

        return Object.keys(fields).map(key => {
            if (typeof fields[key] === 'object' && fields[key] !== null) {
                return (
                    <optgroup key={prefix + key} label={prefix + key}>
                        {this.renderFieldOptions(fields[key], prefix + key + '.')}
                    </optgroup>
                );
            } else {
                return <option key={prefix + key} value={prefix + key}>{prefix + key}</option>;
            }
        });
    }

    async changeOrder(item, direction, index) {
        let itemsListCopyArray = this.state.itemsList || [];
        let originalOrder = item.order || index;
        const itemId = item.id || item.key;
        if (originalOrder === 1 && direction === 1) {
            return
        } else if (originalOrder === itemsListCopyArray.length && direction === -1) {
            return
        }
        let newOrder = originalOrder - direction;
        const itemListCopyObject = {};
        for (let i in itemsListCopyArray) {
            let currentLocationInArray = parseInt(i) + 1;
            let itemIterated = itemsListCopyArray[i] || {};
            let itemIteratedId = itemIterated.id || itemIterated.key;
            //if the new location matches the location of the item currently there we need to give this item that order
            if (newOrder === itemIterated.order) {
                itemIterated.order = originalOrder
            } else if (itemId === itemIteratedId) {
                itemIterated.order = newOrder;
            } else if (!itemIterated.order && currentLocationInArray === newOrder) {
                itemIterated.order = originalOrder;
            } else if (!itemIterated.order) {
                itemIterated.order = currentLocationInArray;
            }
            itemListCopyObject[itemIteratedId] = itemIterated;
            itemListCopyObject[itemIteratedId]['key'] = null;
            itemListCopyObject[itemIteratedId]['id'] = itemIteratedId;
        }
        await appDatabasePrimaryFunctions.ref('teamPopulatedImage').update(itemListCopyObject)
    }

    render() {
        const { loading, itemsList = [], imageUrlPreview = {}, selectedFields = {}, availableFields = {}, rssFeedData = {}, rssFeedLink } = this.state;
        const getValueFromPath = (obj, path) => {
            const allTheSubSets = path.split('.');
            let nextResponse;
            for(const i in allTheSubSets){
                if(nextResponse){
                    nextResponse = nextResponse[allTheSubSets[i]];
                } else {
                    nextResponse = obj[allTheSubSets[i]];
                }
            }
            return nextResponse;
        };

        const imagePath = getValueFromPath(rssFeedData, selectedFields.image);

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="card">
                        <div className="card-body">
                            <p className="admin-header-text" style={{ marginBottom: 0 }}>Top Carousel Items</p>
                            <p className="admin-subheader-text">These items will scroll across the top of your app</p>
                            <div className="row align-items-center" style={{ color: 'black' }}>
                                <div className="col-md-2 d-flex justify-content-start">
                                    <button
                                        className="btn btn-primary btn-lg create-prize-button"
                                        style={{ fontSize: 20, marginLeft: 20 }}
                                        onClick={this.toggle}
                                    >
                                        Add Item
                                    </button>
                                </div>
                                <div className="col-md-2 text-center">
                                    AND/OR
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="rssFeedLink">RSS FEED: </label>
                                        <input
                                            id="rssFeedLink"
                                            name="rssFeedLink"
                                            type="url"
                                            className="form-control"
                                            value={rssFeedLink}
                                            onChange={this.handleRssFeedLinkChange}
                                            placeholder="https://linktorssfeed.com"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 text-center">
                                    <button
                                        className="btn btn-info btn-lg"
                                        style={{ fontSize: 20}}
                                        onClick={this.loadRssFeedData}
                                    >
                                        Set RSS Values
                                    </button>
                                </div>
                                <div className="col-md-2 text-center">
                                    <button
                                        className="btn btn-info btn-lg"
                                        style={{ fontSize: 20}}
                                        onClick={this.populateValuesFromRssFeed}
                                    >
                                        Sync
                                    </button>
                                </div>
                            </div>
                            <div className="admin-grid-container four-columns" style={{ marginTop: 20 }}>
                                {
                                    itemsList.sort((a, b) => a.order > b.order ? 1 : -1).map(function (item, i) {
                                        let orderNumber = item.order || i + 1;
                                        return <div key={i} className="card">
                                            <div className="card-body" align="center">
                                                <p style={{ marginTop: 5 }}>{item.header}</p>
                                                <p style={{ marginTop: 5 }}>{item.link}</p>
                                                {itemsList && itemsList.length > 1 &&
                                                    <>
                                                        <p style={{ marginTop: 5 }}>Order: {orderNumber}</p>
                                                        <p>
                                                            <span style={{ display: orderNumber <= 1 && "none", cursor: "pointer" }} onClick={() => this.changeOrder(item, 1, i + 1)}>
                                                                ⬅️
                                                            </span>
                                                            <span style={{ display: itemsList.length <= i + 1 && "none", cursor: "pointer" }} onClick={() => this.changeOrder(item, -1, i + 1)}>
                                                                ➡️
                                                            </span></p>
                                                    </>
                                                }
                                                <p>
                                                    <img width="80%" height="auto" src={item.image} alt="" />
                                                </p>
                                                {(!item.fromFeed || !this.state.rssFeedLink) ?
                                                    <>
                                                        <button className="btn btn-primary btn-lg edit-button" style={{ marginRight: 5, marginBottom: 10 }} onClick={this.editItem.bind(this)} value={i}><span className="fa fa-ellipsis-v" /> Edit</button>
                                                        <button className="btn btn-primary btn-lg delete-button" style={{ marginBottom: 10 }} onClick={this.deleteItem.bind(this)} value={i}><span className="fa fa-trash-o" /> Delete</button>
                                                    </>
                                                    :
                                                    <>
                                                        <p>From RSS feed, auto populating</p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }, this)
                                }
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ width: '90%' }} id="myModal">
                        <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                        <ModalHeader toggle={this.toggle}>Add Item</ModalHeader>
                        <ModalBody>
                            <div className="container-out">
                                <div className="question-box question-form">
                                    <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                                        <div className="form-group">
                                            <label htmlFor="itemName">Header: </label>
                                            <input id="itemName" name="itemName" type="text" className="form-control" value={this.state.itemName} onChange={this.handleChange} placeholder="Name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="itemLink">Link: </label>
                                            <input id="itemLink" name="itemLink" type="url" className="form-control" value={this.state.itemLink} onChange={this.handleChange} placeholder="https://linktogoto.com" />
                                        </div>
                                        <div className="form-group" align="center">
                                            <label htmlFor="imageUrl" style={{ width: '100%' }}>
                                                Image
                                                <span style={{ cursor: "pointer", display: imageUrlPreview.preview || this.state.imageUrl ? "" : "none" }} onClick={() => this.setState({ editImage: null, fileUploaded: false, imageUrlPreview: {}, imageUrl: null })}>
                                                    ❌
                                                </span>
                                            </label>
                                            <img src={this.state.editImage} width="auto" height="100px" style={{ display: this.state.editingItem ? '' : 'none' }} alt="" />
                                            <BasicDropzone
                                                onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "imageUrl")}
                                                preview={imageUrlPreview.preview}
                                            />
                                        </div>
                                        <div className="form-group text-center">
                                            <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.rssModal} toggle={this.toggleRssModal} style={{ width: '90%' }} id="rssModal">
                        <ModalHeader toggle={this.toggleRssModal}>RSS Feed Preview</ModalHeader>
                        <ModalBody>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Choose Title</th>
                                    <th>Choose Link</th>
                                    <th>Choose Image URL</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <Input
                                            type="select"
                                            value={selectedFields.title}
                                            onChange={(e) => this.handleFieldSelection(e, 'title')}
                                        >
                                            <option value="">Select Title</option>
                                            {this.renderFieldOptions(availableFields)}
                                        </Input>
                                    </td>
                                    <td>
                                        <Input
                                            type="select"
                                            value={selectedFields.link}
                                            onChange={(e) => this.handleFieldSelection(e, 'link')}
                                        >
                                            <option value="">Select Link</option>
                                            {this.renderFieldOptions(availableFields)}
                                        </Input>
                                    </td>
                                    <td>
                                        <Input
                                            type="select"
                                            value={selectedFields.image}
                                            onChange={(e) => this.handleFieldSelection(e, 'image')}
                                        >
                                            <option value="">Select Image URL</option>
                                            {this.renderFieldOptions(availableFields)}
                                        </Input>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            <div className="rss-preview">
                                <h5>Preview:</h5>
                                <div><strong>Title:</strong> {rssFeedData[selectedFields.title]}</div>
                                <div><strong>Link:</strong> <a href={rssFeedData[selectedFields.link]}>{rssFeedData[selectedFields.link]}</a></div>
                                {imagePath &&
                                    <div>
                                        <strong>Image:</strong>
                                        <img src={imagePath} alt="RSS Item" style={{ width: '100%', maxWidth: '200px' }} />
                                    </div>
                                }
                            </div>
                            <div className="text-center mt-3" style={{marginBottom: 20}}>
                                <Button color="primary" onClick={this.handleRssSubmit} disabled={!selectedFields.title || !selectedFields.link || !selectedFields.image}>
                                    Submit
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default SetUpCarousel;
