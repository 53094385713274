import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import {Modal} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base, firebase} from '../../base';
import '../../styles/css/AdminMain.css';
import {
    cleanCSVText,
    convertTimeStampToHumanReadable,
    ErrorMessage,
    findFirstAndLastName,
    getSnapshotFromEndpoint,
    Toast,
    WarningMessage
} from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantVariables: {},
            emailsSent: {},
            emailVariables: {},
            tenantRules: {},
            id_key: "",
            opponents:[],
            currentGame: null,
            modal: false,
            userGameHistory: 0,
            users: [],
            gameName: "",
            loading: true,
            wizardLocation: 'first',
            gameTime: new Date(),
            itemsPerPage: 25,
            itemsToShow: 25,
            deleteAddedItems: false,
            userAnswers: [],
            pointRules: {},
            usersCurrentPickedPlayerCount: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    getCurrentTime(datePassedIn){
        if(!datePassedIn){
            datePassedIn = new Date();
        }
        return `${datePassedIn.getFullYear()}-${`${datePassedIn.getMonth() + 1}`.padStart(2, 0)}-${`${datePassedIn.getDate()}`.padStart(2, 0)}T${`${datePassedIn.getHours()}`.padStart(2, 0)}:${`${datePassedIn.getMinutes()}`.padStart(2, 0)}`;
    }

    componentDidMount() {
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
                this.setState({loading:false})
            }
        });
        this.currentGameIdRef = base.listenTo('currentGame/id', {
            context: this,
            then(gameId){
                if(gameId){
                    // this.setGameToWatch(gameId);
                }
            }
        })
        if(process.env.REACT_APP_HAS_CHECK_IN === "true"){
            this.userCheckedInCountRef = base.listenTo('userCheckedIn', {
                context: this,
                queries: {
                    orderByChild: 'checkedIn',
                    equalTo: true
                },
                asArray: true,
                then(userCheckedInCount){
                    this.setState({userCheckedInCount: userCheckedInCount.length})
                }
            })
        }
        this.totalCurrentGamePlayersRef = base.listenTo('activeCurrentUsers/numberOfUsers', {
            context: this,
            then(data){
                this.setState({
                    usersCurrentPickedPlayerCount: data || 0
                })
            }
        })
        const vm = this;
        appDatabasePrimaryFunctions.ref('opponentLogos').once('value').then(function (snapshot) {
            const opponentLogos = [];
            snapshot.forEach(function (data) {
                let response = data.val();
                if(response.typeof === "object"){
                    opponentLogos.push(response);
                } else {
                    opponentLogos.push({teamName: data.key, teamLogo: response})
                }
            })
            vm.setState({
                opponents:opponentLogos
            })
        })
        if(process.env.REACT_APP_HAS_BADGES === "true") {
            appDatabasePrimaryFunctions.ref('badges').orderByChild('secretCode').equalTo(null).once('value').then(function (snapshot) {
                const arrayOfBadges = [];
                snapshot.forEach(function (data) {
                    let badgeData = data.val();
                    badgeData.id = data.key;
                    arrayOfBadges.push(badgeData)
                })
                vm.setState({
                    badges: arrayOfBadges
                })
            });
        }
        if(process.env.REACT_APP_HAS_PLAYER_PICK === "true"){
            appDatabasePrimaryFunctions.ref('pointRules').once('value').then(function(snapshot){
                vm.setState({
                    pointRules: snapshot.val()
                })
            });
            this.currentSelectedPlayers1Ref = base.syncState('currentSelectedPlayers1', {
                context: this,
                state: "currentSelectedPlayers1"
            })
            this.allPlayersRef = base.bindToState('players', {
                context: this,
                state: 'allPlayers',
                asArray: true
            })
        }
    }

    downloadUsers(){
        this.setState({loading: true});
        const vm = this;
        let csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Prize Sent,Code,Time Stamp\n';
        Promise.all([getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
            let users = {};
            if(snapshots[0].exists() && snapshots[0].val()){
                users = snapshots[0].val();
            }
            const currentUsersState = await vm.organizeUserAnswers();
            currentUsersState.forEach(function(row) {
                let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
                csv += (users[row.uid].email || row.uid || "");
                csv+= ",";
                csv+= (cleanCSVText(firstName) || "");
                csv+= ",";
                csv+= (cleanCSVText(secondName) || "");
                csv+= ",";
                csv+= (users[row.uid].phoneNumber || "");
                csv+= ",";
                csv+= (cleanCSVText(users[row.uid].zipCode) || "");
                csv+= ",";
                csv+= (cleanCSVText(users[row.uid].address) || "");
                csv+= ",";
                csv+= (users[row.uid].birthday || "");
                csv+= ",";
                csv+= (users[row.uid].optIn || "");
                csv+= ",";
                csv+= (cleanCSVText(row.rewardSent) || "NONE");
                csv+= ",";
                csv+= (cleanCSVText(row.code) || "-");
                csv+= ",";
                csv+= (row.timeStamp?convertTimeStampToHumanReadable(row.timeStamp) : "-");
                csv += "\n";
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            hiddenElement.target = '_blank';
            const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
            hiddenElement.download = "current_game_users_" + date_got;
            hiddenElement.click();
            vm.setState({loading: false})
        })
    }

    setGameToWatch(id){
        if(this.userGameHistoryRef){
            appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        if(id){
            const vm = this;
            this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
                vm.setState({
                    userGameHistory: snapshot.numChildren()
                })
            })
        }
    }

    componentWillUnmount() {
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.currentGameIdRef);
        if(this.allPlayersRef){
            base.removeBinding(this.allPlayersRef);
        }
        if(this.userCheckedInCountRef){
            base.removeBinding(this.userCheckedInCountRef);
        }
        if(this.currentSelectedPlayers1Ref){
            base.removeBinding(this.currentSelectedPlayers1Ref);
        }
        base.removeBinding(this.totalCurrentGamePlayersRef);
    }

    decipherPointValue(key){
        const pointRules = this.state.pointRules || {};
        let pointValue;
        if(key === "goodPlays"){
            pointValue = pointRules["perGoodPlay"];
        } else if(key === "goals"){
            pointValue = pointRules["perGoal"];
        } else if(key === "assists"){
            pointValue = pointRules["perAssist"];
        }
        return pointValue;
    }

    async changePlayerStat(direction, player, stat){
        let updateObject = {};
        let playerId = player.id || player.key;
        let incrementValue = 1;
        if(!direction || !player || !playerId || !stat){
            return;
        }
        if(direction === "down"){
            incrementValue = -1;
        }
        updateObject['currentSelectedPlayers/' + player.name + "/" + stat] = firebase.database.ServerValue.increment(incrementValue);
        updateObject['currentSelectedPlayers1/' + player.name + "/" + stat] = firebase.database.ServerValue.increment(incrementValue);
        updateObject['players/' + playerId + "/" + stat] = firebase.database.ServerValue.increment(incrementValue);
        await appDatabasePrimaryFunctions.ref().update(updateObject);
    }

    async getUsersToGivePointsTo(player, stat){
        let pointValue = this.decipherPointValue(stat);
        if(!pointValue){
            return;
        } else {
            pointValue = parseInt(pointValue);
        }
        const playersWithCorrectChoice = await appDatabasePrimaryFunctions.ref('userCurrentPickedPlayer').orderByChild("playerID").equalTo(player.name).once('value');
        const updateObjectArray = [];
        let objectToPush = {};
        let childrenCount = playersWithCorrectChoice.numChildren();
        let count = 0;
        playersWithCorrectChoice.forEach(function (data){
            count++;
            let userId = data.key;
            if(!userId){
                return false;
            }
            objectToPush['userPoints/' + userId + "/points"] = firebase.database.ServerValue.increment(pointValue);
            objectToPush['currentGameUserPoints/' + userId] = firebase.database.ServerValue.increment(pointValue);
            objectToPush['userLevel/' + userId + "/totalPoints"] = firebase.database.ServerValue.increment(pointValue);
            if(Object.keys(objectToPush).length >= 100){
                updateObjectArray.push(structuredClone(objectToPush));
                objectToPush = {};
            } else if(childrenCount === count){
                updateObjectArray.push(structuredClone(objectToPush));
                objectToPush = {};
            }
        })
        for(const i in updateObjectArray){
            try{
                await appDatabasePrimaryFunctions.ref().update(updateObjectArray[i]);
            } catch (e) {
                console.log(e)
            }
        }
        await this.rankPlayers();
    }

    async rankPlayers(){
        const rankedCurrentGameUserPoints = await appDatabasePrimaryFunctions.ref('currentGameUserPoints').orderByValue().once('value');
        const totalPlayers = rankedCurrentGameUserPoints.numChildren();
        const rankedPlayers = {};
        let count = 0;
        rankedCurrentGameUserPoints.forEach(function (data){
            const uid = data.key;
            const rank = totalPlayers-count;
            rankedPlayers['currentGameUserRank/' + uid] = rank;
            if(rank > totalPlayers){
                rankedPlayers['currentGameUserRank/' + uid] = 0
            }
            count = count + 1;
        })
        await appDatabasePrimaryFunctions.ref().update(rankedPlayers);
    }

    async arrowClicked(direction, player, stat){
        this.setState({loading:true})
        await this.changePlayerStat(direction, player, stat)
        if(direction === "up"){
            await this.getUsersToGivePointsTo(player, stat);
        }
        this.setState({loading:false})
    }

    async createGame(){
        let createGameObject = {};
        const gameId = appDatabasePrimaryFunctions.ref().push().key;
        let gameTime = this.state.gameTime;
        let opponentId = this.state.opponent || "";
        const opponents = this.state.opponents || [];
        let opponentObject = false;
        for(const i in opponents){
            if((opponents[i].key === opponentId) || opponents[i].teamName === opponentId){
                opponentObject = opponents[i];
            }
        }

        if(opponents && opponents.length > 0 && (!opponentId || !opponentObject)){
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game needs an opponent'
            })
            return
        }

        let gameAddress = this.state.gameAddress || "";
        gameAddress = gameAddress.trim();
        if(!gameAddress){
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game needs an address'
            })
            return
        }
        let cleanAddress = gameAddress.split(' ').join('+');
        if(!gameTime){
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game needs a game time!'
            })
            return
        }
        gameTime = gameTime.getTime();
        let gameName = this.state.gameName.trim();
        if(!gameName){
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'The game needs a name!'
            })
            return
        }
        this.setState({loading:true})
        const urlToGet = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + cleanAddress + '&key=AIzaSyBS2Ld31MCU5IRtGkzG5OhQ_I4L7TDtrww';
        const googleResponse = await fetch(urlToGet)
            .then(response => response.json())
            .then(data => {
                return data
            })
            .catch(e => {
                return false
            })
        if(googleResponse.status === "ZERO_RESULTS" || !googleResponse){
            this.setState({loading:false})
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'Game address not found!'
            })
            return;
        }
        createGameObject.coordinates = {};
        createGameObject.coordinates.lat = googleResponse.results[0].geometry.location.lat;
        createGameObject.coordinates.lng = googleResponse.results[0].geometry.location.lng;
        createGameObject.opponent = {};
        createGameObject.opponent.teamName = opponentId;
        createGameObject.gameName =  gameName;
        createGameObject.active =  false;
        createGameObject.statsFeed = "auto";
        createGameObject.address = gameAddress;
        createGameObject.date = gameTime;
        createGameObject.playerPickEnabled = false;
        let currentGame = this.state.currentGame;
        if(currentGame.gameName){
            currentGame['users'] = this.state.usersCurrentPickedPlayerCount || 0;
            currentGame['checkIns'] = this.state.userCheckedInCount || 0;
            let playerPickedObject = {};
            currentGame['playerPicks'] = playerPickedObject;
            currentGame['active'] = null;
            currentGame['answered'] = null;
            currentGame['prizeType'] = null;
            currentGame['firstStart'] = null;
            currentGame['timeMinutes'] = null;
        }
        const vm = this;
        await base.remove("currentGame/rewards");
        base.push('games', {
            data: currentGame,
            then(err) {
                if (!err) {
                    createGameObject.id = gameId;
                    createGameObject.timeStamp = Date.now();
                    vm.setState({
                        currentGame: createGameObject,
                        modal: false,
                        loading: false,
                        currentUserAnswersState: []
                    }, () => {
                        vm.clearDataFromGame();
                    })
                } else {
                    alert("Error Occurred: " + err);
                    vm.setState({
                        loading: false
                    })
                }
            }
        })
    }

    stopGame(){
        let currentGame = this.state.currentGame;
        currentGame.active = false;
        this.setState({
            currentGame: currentGame
        })
        Toast.fire({
            title: 'Game Stopped'
        })
    }

    startGame() {
        let currentGame = this.state.currentGame;
        this.setState({loading:true})
        currentGame.timeStamp = Date.now()
        currentGame.active = true;
        this.setState({
            currentGame: currentGame,
            loading: false
        });
        Toast.fire({
            title: 'Game Started'
        })
    }

    changeGameValue(name, value, insideObjectName){
        let currentGame = this.state.currentGame;
        if(!insideObjectName){
            currentGame[name] = value;
        } else {
            if(!currentGame[name]){
                currentGame[name] = {};
            }
            currentGame[name][insideObjectName] = value;
        }
        this.setState({
            currentGame: currentGame
        })
    }

    getSnapshotFromEndpoint(endpoint, numberOfResults=null, searchData=null, orderBy=null) {
        let baseRef = appDatabasePrimaryFunctions.ref(endpoint);
        if(orderBy){
            baseRef = baseRef.orderByChild(orderBy)
        }
        if(numberOfResults){
            baseRef = baseRef.limitToFirst(numberOfResults);
        }
        return baseRef.once('value').then(function(snapshot){
            return snapshot;
        })
    }

    async organizeUserAnswers(numberOfResults=0, searchData=null){
        let playedUsers = [];
        const currentGame = this.state.currentGame || {};
        if(!currentGame.id){
            return
        }
        await Promise.all([this.getSnapshotFromEndpoint('userAnswers', numberOfResults, searchData, 'timeStamp'), this.getSnapshotFromEndpoint('emailsSent'), this.getSnapshotFromEndpoint('users'), appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(currentGame.id).equalTo(currentGame.id).once('value').then(function(snapshot){return snapshot})]).then(async function(snapshots) {
            let userAnswers = snapshots[0].val() || {};
            let emailsSent = snapshots[1].val() || {};
            let users = snapshots[2].val() || {};
            let usersHistoryRef = snapshots[3];
            usersHistoryRef.forEach(function(userHistoryDataRef){
                let answerObject = {};
                let userId = userHistoryDataRef.key;
                let userAnswerObject = userAnswers[userId] || {};
                let userEmail = "No Email Collected";
                if(users[userId] && users[userId].email) {
                    userEmail = users[userId].email
                }
                answerObject['email'] = userEmail || userId;
                answerObject['timeStamp'] = userAnswerObject.timeStamp || "";
                answerObject['uid'] = userAnswerObject.uid || userId || "";
                const emailsSentObject = emailsSent[btoa(userEmail)] || emailsSent[userAnswerObject.uid]
                if (emailsSentObject) {
                    answerObject['rewardSent'] = emailsSentObject.rewardName || "";
                    answerObject['code'] = emailsSentObject.code || "";
                }
                if(searchData){
                    const cleanSearchData = searchData.toLowerCase();
                    const lowerCaseEmail = userEmail.toLowerCase();
                    let lowerCaseRewardName = "";
                    if(emailsSent[userAnswerObject.uid] && emailsSent[userAnswerObject.uid].name){
                        lowerCaseRewardName = emailsSent[userAnswerObject.uid].name.toLowerCase();
                    }
                    if(lowerCaseEmail.indexOf(cleanSearchData) !== -1  || lowerCaseRewardName.indexOf(cleanSearchData) !== -1){
                        playedUsers.push(answerObject);
                    }
                } else {
                    playedUsers.push(answerObject);
                }
            })
        })
        return playedUsers
    }

    handleChange (evt) {
      let target = evt.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
        matchesAdded: [],
        gameName: "",
        rewardsAdded: [],
        codes: false,
        rewardToAdd: '',
        rewardAmount: 0,
        codesArray: '',
        qrCodes: false,
        chanceToWin: null
      });
    }

    convertStatToNumber(stat){
        if(typeof stat === 'number') return stat;
        return parseInt(stat);
    }

    async addPlayer(player){
        if(player){
            let playerId = player.id || player.key;
            if(!playerId || !player.name) return;
            const updateObject = {};
            console.log(player)
            player['assists'] = this.convertStatToNumber(player['assists']);
            player['goals'] = this.convertStatToNumber(player['goals']);
            player['goodPlays'] = this.convertStatToNumber(player['goodPlays']);
            player['number'] = player['number'];
            updateObject['currentSelectedPlayers/' + player.name] = player;
            updateObject['currentSelectedPlayers1/' + player.name] = player;
            this.setState({loading: true})
            await appDatabasePrimaryFunctions.ref().update(updateObject);
            this.setState({loading: false})
        }
    }

    async removePlayer(player){
        if(player){
            let playerId = player.id || player.key;
            if(!playerId || !player.name){
                return;
            }
            const updateObject = {};
            updateObject['currentSelectedPlayers/' + player.name] = null;
            updateObject['currentSelectedPlayers1/' + player.name] = null;
            this.setState({loading: true})
            await appDatabasePrimaryFunctions.ref().update(updateObject);
            this.setState({loading: false})
        }
    }

    async clearDataFromGame(){
        this.setState({loading: true})
        await appDatabasePrimaryFunctions.ref("currentGameUserRank").set(null);
        await appDatabasePrimaryFunctions.ref("currentGameUserPoints").set(null);
        await appDatabasePrimaryFunctions.ref("userCurrentPickedPlayer").set(null);
        await appDatabasePrimaryFunctions.ref("userCheckedIn").set(null);
        await appDatabasePrimaryFunctions.ref("activeCurrentUsers/numberOfUsers").set(0);
        await appDatabasePrimaryFunctions.ref("currentSelectedPlayers").set(null);
        await appDatabasePrimaryFunctions.ref("currentSelectedPlayers1").set(null);
        await appDatabasePrimaryFunctions.ref("uniqueUsersTonight").set(null);
        await appDatabasePrimaryFunctions.ref("usersWithPlayersPicked").set(null);
        await appDatabasePrimaryFunctions.ref("currentGame/active").set(false);
        const players = await appDatabasePrimaryFunctions.ref("players").once('value');
        const toUpdateObject = {};
        players.forEach(function (data){
            const player = data.val();
            toUpdateObject[`players/${player.id}/assists`] = 0
            toUpdateObject[`players/${player.id}/goals`] = 0
            toUpdateObject[`players/${player.id}/goodPlays`] = 0
        })
        await appDatabasePrimaryFunctions.ref().update(toUpdateObject);
        this.setState({
            loading: false,
            currentUserAnswersState: [],
            modal: false
        })

    }

    async resetGame(){
        const result = await WarningMessage.fire({
            title: 'STOP!',
            text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
            confirmButtonText: 'YES I WANT TO RESET THE GAME'
        })
        if(result.value){
            await this.clearDataFromGame();
        }
    }

    navButtonClicked(direction){
      const currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        });
        this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'next' && currentLocation === 'second'){
            this.setState({
              wizardLocation: 'fourth',
            });
            this.toggleWizardLocation('fourth', 'first', 'second', 'third')
      } else if(direction === 'next' && currentLocation === 'third'){
          this.setState({
              wizardLocation: 'fourth',
          });
          this.toggleWizardLocation('fourth', 'first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'fourth'){
          this.setState({
              wizardLocation: 'third',
          });
          this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'fourth'){
          this.setState({
              wizardLocation: 'second',
          });
          this.toggleWizardLocation('second', 'first', 'fourth', 'third')
      } else {
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2, otherTab3){
      this.setState({
        wizardLocation: tabClicked
      });
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      if(document.getElementById(otherTab3)){
          document.getElementById(otherTab3).classList.remove('active');
          document.getElementById(otherTab3).classList.remove('show');
      }
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
      if(document.getElementById(otherTab3)){
          document.getElementById(otherTab3+'1').classList.remove('active');
          document.getElementById(otherTab3+'1').classList.remove('show');
      }
    }

    async switchStatsPrizes(){
        if(this.state.showStats){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats:false
            })
        }else{
            document.getElementById('showPrizes').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            this.setState({
                loading: true
            })
            const organizeUserAnswers = await this.organizeUserAnswers();
            this.setState({
                showStats:true,
                loading: false,
                currentUserAnswersState: organizeUserAnswers
            })
        }
    }

    render() {
        const createsGamesAutomatically = process.env.REACT_APP_HAS_STAT_FEED_THAT_CREATES_GAMES === "true";
        const playerPickEnabled = process.env.REACT_APP_HAS_PLAYER_PICK === "true";
        const checkInEnabled = process.env.REACT_APP_HAS_CHECK_IN === "true";
        const userCheckedInCount = this.state.userCheckedInCount || 0;
        const userCurrentPickedPlayerCount = this.state.usersCurrentPickedPlayerCount || 0;
        const currentSelectedPlayers1 = this.state.currentSelectedPlayers1 || {};
        const badges = this.state.badges || [];
        const allPlayers = this.state.allPlayers;
        let selectedGame = this.state.currentGame || null;
        let gameName = null;
        let gameTimeFirstPart = null;
        let gameTimeSecondPart = null;
        let gameScheduledStatus = "completed";
        if(selectedGame && selectedGame.address){
            gameTimeFirstPart = new Date(selectedGame.date).toLocaleDateString();
            gameTimeSecondPart = new Date(selectedGame.date).toLocaleTimeString();
            gameName = selectedGame.gameName;
        } else {
            selectedGame = null;
        }
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                  {playerPickEnabled &&
                    <>
                        <div className="col-md-3">
                            <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && selectedGame.active ? '' : 'none', float:'left'}}>
                                <p style={{lineHeight:'50px'}}>Game Live</p>
                            </div>
                            <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && !selectedGame.active ? '' : 'none', float:'left'}}>
                                <p style={{lineHeight:'50px'}}>Game Not Live</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: selectedGame && selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                            <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && !selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Start Game</button>
                        </div>
                    </>
                  }
                  {!createsGamesAutomatically &&
                      <div className="col-md-6" style={{textAlign:'right'}}>
                          <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                          <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                      </div>
                  }
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4" style={{color: "black", border: "solid 1px #d3d3d3", margin:10, borderRadius: 5}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <u style={{color:"#353839"}}>Game Time & Address</u>
                                    <br/>
                                    <span style={{fontSize:18}}>{gameTimeFirstPart} -- {gameTimeSecondPart}</span>
                                    <br/>
                                    <span style={{fontSize:18}}>{selectedGame && selectedGame.address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" style={{color: "black", border: "solid 1px #d3d3d3", marginTop:10, marginBottom:10, borderRadius: 5}}>
                            <div className="row">
                                {playerPickEnabled &&
                                    <div className="col-md-6" style={{textAlign: 'center'}}>
                                        <span style={{fontSize: 12}}>Current Game Players</span>
                                        <footer className="value-text">{userCurrentPickedPlayerCount}</footer>
                                    </div>
                                }
                                {checkInEnabled &&
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <span style={{fontSize:12}}>Players Checked In</span>
                                        <footer className="value-text">{userCheckedInCount}</footer>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" style={{color:"black"}}>
                    {playerPickEnabled &&
                        <div className="col-md-8">
                            <span style={{fontSize:12}}>Current Selectable Players</span>
                            {allPlayers && allPlayers.length > 0 ?
                                <>
                                    <div className="current-players">
                                        {allPlayers && allPlayers.length > 0 &&
                                            allPlayers.map(function (player, i) {
                                                if(!currentSelectedPlayers1[player.name]){
                                                    return
                                                }
                                                return <div key={i} className="row" style={{color: "black", margin:10, border: "black 1px solid", padding: 10}}>
                                                    <div className="col-md-2" style={{textAlign:"center"}}>
                                                        <a href="#">
                                                            <img src={player.url} alt=""/>
                                                        </a>
                                                        <div>
                                                            {player.name}
                                                            <br/>
                                                            {player.number}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
                                                        <div className="row d-flex justify-content-center align-items-center">
                                                            <div className="editing-carets col-md-3">
                                                                <p>{process.env.REACT_APP_IS_HOCKEY === "true" ? "GOALS" : "POINTS"}</p>
                                                                <i className="fa fa-caret-up fa-2x" onClick={() => this.arrowClicked("up", player, "goals")}/>
                                                                <p>{player.goals}</p>
                                                                <i className="fa fa-caret-down fa-2x" onClick={() => this.arrowClicked("down", player, "goals")}/>
                                                            </div>
                                                            <div className="editing-carets col-md-3">
                                                                <p>ASTS</p>
                                                                <i className="fa fa-caret-up fa-2x" onClick={() => this.arrowClicked("up", player, "assists")}/>
                                                                <p>{player.assists}</p>
                                                                <i className="fa fa-caret-down fa-2x" onClick={() => this.arrowClicked("down", player, "assists")}/>
                                                            </div>
                                                            <div className="editing-carets col-md-3">
                                                                <p>{process.env.REACT_APP_IS_HOCKEY === "true" ? "SOG" : "REBOUNDS"}</p>
                                                                <i className="fa fa-caret-up fa-2x" onClick={() => this.arrowClicked("up", player, "goodPlays")}/>
                                                                <p>{player.goodPlays}</p>
                                                                <i className="fa fa-caret-down fa-2x" onClick={() => this.arrowClicked("down", player, "goodPlays")}/>
                                                            </div>
                                                            <div className="col-md-2" style={{display:"flex"}}>
                                                                <button className="btn btn-danger" onClick={()=>this.removePlayer(player)}>
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }, this)
                                        }
                                    </div>
                                    <span style={{fontSize:12}}>All Players</span>
                                    <div className="out-players">
                                        {this.state.showHiddenPlayers &&
                                            <button className="btn btn-danger" onClick={()=>this.setState({showHiddenPlayers: false})}>
                                                Hide Players
                                            </button>
                                        }
                                        {allPlayers && allPlayers.length > 0 && this.state.showHiddenPlayers &&
                                            allPlayers.map(function (player, i) {
                                                if(currentSelectedPlayers1[player.name] || !player.id){
                                                    return
                                                }
                                                return <div key={i} className="row" style={{color: "black", margin:10, border: "black 1px solid", padding: 10}}>
                                                    <div className="col-md-2" style={{textAlign:"center"}}>
                                                        <a href="#">
                                                            <img src={player.url} alt=""/>
                                                        </a>
                                                        <div>
                                                            {player.name}
                                                            <br/>
                                                            {player.number}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
                                                        <div className="row d-flex justify-content-center align-items-center">
                                                            <div className="col-md-2" style={{display:"flex"}}>
                                                                <button className="btn btn-success" onClick={()=>this.addPlayer(player)}>
                                                                    Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }, this)
                                        }
                                        {!this.state.showHiddenPlayers &&
                                            <button className="btn btn-success" onClick={()=>this.setState({showHiddenPlayers: true})}>
                                                Show All Players
                                            </button>
                                        }
                                    </div>
                                </>
                                :
                                <div>
                                    No Players -- Create Players on the players tab
                                </div>
                            }
                        </div>
                    }
                    <div className="col-md-4">
                        <div style={{border: "none", boxShadow: "0 1px 2px 0 rgb(0 0 0 / 10%)", padding: 20}}>
                            Game Controls
                            {process.env.REACT_APP_HAS_PLAYER_PICK === 'true' &&
                                <div className="form-group col-md-4" style={{textAlign: "center"}}>
                                    <label className="col-form-label" htmlFor="userName3">Player Pick</label>
                                    <label className="switch" style={{width: 60}}>
                                        <input type="checkbox" checked={selectedGame && selectedGame.playerPickEnabled} onClick={()=>{this.changeGameValue("playerPickEnabled", !selectedGame.playerPickEnabled)}}/>
                                        <span className="slider round"/>
                                    </label>
                                </div>
                            }
                            {process.env.REACT_APP_HAS_STAT_FEED === 'true' &&
                                <div className="form-group col-md-4" style={{textAlign: "center"}}>
                                    <label className="col-form-label" htmlFor="userName3">Stats Auto?</label>
                                    <label className="switch" style={{ width: 60}}>
                                        <input type="checkbox" checked={selectedGame && selectedGame.statsFeed === "auto"} onClick={()=>{
                                            if(selectedGame && selectedGame.statsFeed === "auto")
                                            {
                                                this.changeGameValue("statsFeed", 'manual')
                                            } else {
                                                this.changeGameValue("statsFeed", 'auto')
                                            }
                                        }}/>
                                        <span className="slider round"/>
                                    </label>
                                </div>
                            }
                        </div>
                        {process.env.REACT_APP_HAS_BADGES === 'true' &&
                            <div style={{border: "none", boxShadow: "0 1px 2px 0 rgb(0 0 0 / 10%)", padding: 20, marginTop: 20}}>
                                Current Badges
                                {selectedGame && selectedGame.currentBadges ?
                                    <ul>
                                        {Object.keys(selectedGame.currentBadges).map(function (key, i) {
                                            return <li key={i}>
                                                {selectedGame.currentBadges[key].name} -- <button
                                                className="btn btn-danger"
                                                onClick={() => this.changeGameValue('currentBadges', null, key)}>Remove</button>
                                            </li>
                                        }, this)}
                                    </ul>
                                    :
                                    <div>
                                        No Check-In Badges Added Yet
                                    </div>
                                }
                                <div>
                                    <select name="badgeToAdd" id="badgeToAdd" onChange={this.handleChange}
                                            style={{marginRight: 10}}>
                                        <option value=""/>
                                        {badges.map(function (item, i) {
                                            if (selectedGame.currentBadges && selectedGame.currentBadges[item.id]) {
                                                return;
                                            }
                                            return <option key={i} value={item.id}>
                                                {item.name}
                                            </option>
                                        })}
                                    </select>
                                    <button className="btn btn-success" onClick={() => {
                                        let badgeToAddId = this.state.badgeToAdd;
                                        let badges = this.state.badges;
                                        let badge;
                                        for (const i in badges) {
                                            if (badgeToAddId && badges[i].id === badgeToAddId) {
                                                badge = badges[i];
                                            }
                                        }
                                        if (badgeToAddId && badge) {
                                            this.changeGameValue('currentBadges', badge, badge.id);
                                        }
                                    }}>Add Badge
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    {/*<ul className="nav nav-pills bg-dark-light nav-justified mb-3">*/}
                    {/*    <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>*/}
                    {/*        <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">*/}
                    {/*            <span className="fa fa-pencil-square-o"/>*/}
                    {/*            <span className="d-none d-sm-inline"> The Basics</span>*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*    <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'third', 'first')}>*/}
                    {/*        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">*/}
                    {/*            <span className="fa fa-trophy"/>*/}
                    {/*            <span className="d-none d-sm-inline"> Add Prizes</span>*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*    <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>*/}
                    {/*        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">*/}
                    {/*            <span className="fa fa-sign-out"/>*/}
                    {/*            <span className="d-none d-sm-inline"> Finish</span>*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                        {this.state.opponents && this.state.opponents.length > 0 &&
                                            <div className="form-group row mb-3">
                                                <label className="col-md-3 col-form-label" htmlFor="name3"> Select Opponent</label>
                                                <div className="col-md-9">
                                                    <select name="opponent" className="form-control" onChange={this.handleChange}>
                                                        <option/>
                                                        {
                                                            this.state.opponents.map(function(item,index){
                                                                return(
                                                                    <option value={item.key} key={index}>{item.teamName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="gameTime"> Set Game Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.gameTime} onChange={date => this.setState({gameTime: date})}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="gameAddress"> Address</label>
                                            <div className="col-md-9">
                                                <input id="gameAddress" name="gameAddress" type="text" className="form-control" value={this.state.gameAddress} onChange={this.handleChange} placeholder="New York, New York" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                      {/*<div className="tab-pane" id="second">*/}
                      {/*  <div className="form-horizontal">*/}
                      {/*      {rewardsAdded && rewardsAdded.length > 0 &&*/}
                      {/*          <div className="row" style={{marginBottom: 10}}>*/}
                      {/*              <div className="col-md-12 justify-content-center" style={{textAlign:"center"}}>*/}
                      {/*                  <h4 style={{color:'black'}}>Items Added To Spin To Win</h4>*/}
                      {/*                  <div style={{*/}
                      {/*                      marginLeft: "auto",*/}
                      {/*                      marginRight: "auto",*/}
                      {/*                      width: 210,*/}
                      {/*                      outline: "1px black solid",*/}
                      {/*                      display: "flex",*/}
                      {/*                      flexDirection: "row",*/}
                      {/*                      flexWrap: "wrap",*/}
                      {/*                      padding: 5*/}
                      {/*                  }}>*/}
                      {/*                      {*/}
                      {/*                          rewardsAdded.map(function(item, index){*/}
                      {/*                              let rewardExists = false;*/}
                      {/*                              let reward = "";*/}
                      {/*                              if(item.reward){*/}
                      {/*                                  rewardExists = true*/}
                      {/*                                  reward = item.reward*/}
                      {/*                              }*/}
                      {/*                              return(*/}
                      {/*                                  <div key={index} style={{display: 'flex', flexDirection: "column", justifyContent: "flex-end", alignItems: "center"}}>*/}
                      {/*                                      {rewardExists &&*/}
                      {/*                                          <div style={{maxWidth: 30, maxHeight: 60}}>*/}
                      {/*                                              <img width="auto" height="auto" src={reward.spinImage} alt="" style={{maxWidth: "100%", maxHeight: "100%"}}/>*/}
                      {/*                                          </div>*/}
                      {/*                                      }*/}
                      {/*                                      <div style={{width: 30, height: 60, margin: 5}}>*/}
                      {/*                                          {!rewardExists ?*/}
                      {/*                                              <div className="overlay" style={{backgroundColor: tenantVariables.tileBackgroundColor, color: tenantVariables.tileTextColor}}>*/}
                      {/*                                                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>*/}
                      {/*                                                      {tenantVariables.noPrizeTileText || "No Prize"}*/}
                      {/*                                                  </div>*/}
                      {/*                                              </div>*/}
                      {/*                                              :*/}
                      {/*                                              <div className="overlay" style={{backgroundColor: tenantVariables.tileBackgroundColor, color: tenantVariables.tileTextColor}}>*/}
                      {/*                                                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>*/}
                      {/*                                                      {reward.rewardDisplayName || reward.rewardName}*/}
                      {/*                                                  </div>*/}
                      {/*                                              </div>*/}
                      {/*                                          }*/}
                      {/*                                      </div>*/}
                      {/*                                  </div>*/}
                      {/*                              )*/}
                      {/*                          }, this)*/}
                      {/*                      }*/}
                      {/*                  </div>*/}
                      {/*              </div>*/}
                      {/*          </div>*/}
                      {/*      }*/}
                      {/*      <div className="row">*/}
                      {/*          <div className="col-12">*/}
                      {/*              <div className="form-check">*/}
                      {/*                  <input value={this.state.noPrizeSquare} className="form-check-input" id="noPrizeSquare" name="noPrizeSquare" type="checkbox" checked={this.state.noPrizeSquare} onChange={this.handleChange} />*/}
                      {/*                  <label className="form-check-label" htmlFor="noPrizeSquare">No Prize</label>*/}
                      {/*              </div>*/}
                      {/*              {!this.state.noPrizeSquare &&*/}
                      {/*                  <>*/}
                      {/*                      <div className="form-group row mb-3">*/}
                      {/*                          <label className="col-md-3 col-form-label" htmlFor="name3"> Select Prize</label>*/}
                      {/*                          <div className="col-md-9">*/}
                      {/*                              <select className="form-control" onChange={this.saveReward}>*/}
                      {/*                                  <option/>*/}
                      {/*                                  {*/}
                      {/*                                      this.state.ticketList.map(function(item,index){*/}
                      {/*                                          return(*/}
                      {/*                                              <option value={item.key} key={index}>{item.rewardName}</option>*/}
                      {/*                                          )*/}
                      {/*                                      })*/}
                      {/*                                  }*/}
                      {/*                              </select>*/}
                      {/*                          </div>*/}
                      {/*                      </div>*/}
                      {/*                      /!*<div className="form-check">*!/*/}
                      {/*                      /!*    <input value={this.state.allElsePrize} className="form-check-input" id="allElsePrize" name="allElsePrize" type="checkbox" checked={this.state.allElsePrize} onChange={this.handleChange} />*!/*/}
                      {/*                      /!*    <label className="form-check-label" htmlFor="allElsePrize">All Else Prize</label>*!/*/}
                      {/*                      /!*</div>*!/*/}
                      {/*                      {!this.state.allElsePrize &&*/}
                      {/*                          <>*/}
                      {/*                              <div className="form-group row mb-3">*/}
                      {/*                                  <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Prize To Give Out?</label>*/}
                      {/*                                  <div className="col-md-9">*/}
                      {/*                                      <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />*/}
                      {/*                                  </div>*/}
                      {/*                              </div>*/}

                      {/*                              <div className="form-check">*/}
                      {/*                                  <input value={this.state.codes} className="form-check-input" id="codes" name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />*/}
                      {/*                                  <label className="form-check-label" htmlFor="codes">Redeem Codes?</label>*/}
                      {/*                              </div>*/}
                      {/*                          </>*/}
                      {/*                      }*/}
                      {/*                  </>*/}
                      {/*              }*/}

                      {/*              <div className="form-check" style={{display: this.state.codes && !this.state.noPrizeSquare && !this.state.allElsePrize ? 'flex' : 'none', marginTop: 5, marginBottom: 5}}>*/}
                      {/*                  <input value={this.state.qrCodes} className="form-check-input" id="qrCodes" name="qrCodes" type="checkbox" checked={this.state.qrCodes} onChange={this.handleChange} />*/}
                      {/*                  <label className="form-check-label" htmlFor="codes">Convert Codes To QRCode?</label>*/}
                      {/*              </div>*/}

                      {/*              <div className="form-group row mb-3" style={{display: this.state.codes && !this.state.noPrizeSquare && !this.state.allElsePrize ? 'flex' : 'none' }}>*/}
                      {/*                  <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>*/}
                      {/*                  <div className="col-md-9">*/}
                      {/*                      <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>*/}
                      {/*                  </div>*/}
                      {/*              </div>*/}

                      {/*              <div className="form-group row mb-3" align="center">*/}
                      {/*                  <div className="col-md-12">*/}
                      {/*                      <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>*/}
                      {/*                          {this.state.noPrizeSquare ? "Add Blank Square" : "Add Prize"}*/}
                      {/*                      </button>*/}
                      {/*                  </div>*/}
                      {/*              </div>*/}

                      {/*              /!*<div className="form-group mb-3">*!/*/}
                      {/*              /!*    <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">*!/*/}
                      {/*              /!*        {*!/*/}
                      {/*              /!*            rewardsAdded.map(function(item,index){*!/*/}
                      {/*              /!*                let codesText = "No";*!/*/}
                      {/*              /!*                if(item.codes){*!/*/}
                      {/*              /!*                    codesText = "Yes";*!/*/}
                      {/*              /!*                }*!/*/}
                      {/*              /!*                return(*!/*/}
                      {/*              /!*                    <div key={index} className="form-group">*!/*/}
                      {/*              /!*                        <li>) {item.amount} - {item.reward.rewardName}; Codes: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(index)}/></li>*!/*/}
                      {/*              /!*                    </div>*!/*/}
                      {/*              /!*                )*!/*/}
                      {/*              /!*            })*!/*/}
                      {/*              /!*        }*!/*/}
                      {/*              /!*    </ol>*!/*/}
                      {/*              /!*</div>*!/*/}

                      {/*          </div>*/}
                      {/*      </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      {/*  <div className="tab-pane fade" id="third">*/}
                      {/*      <form id="otherForm" method="post" action="#" className="form-horizontal"/>*/}
                      {/*          <div className="row">*/}
                      {/*              <div className="col-12">*/}
                      {/*                  <div className="text-center">*/}
                      {/*                      <div className="row form-group">*/}
                      {/*                          <label className="col-md-3 col-form-label" htmlFor="chanceToWin"> Odds To Win = 1/</label>*/}
                      {/*                          <div className="col-md-9">*/}
                      {/*                              <input id="chanceToWin" name="chanceToWin" type="number" className="form-control" value={this.state.chanceToWin} onChange={this.handleChange} placeholder="5" />*/}
                      {/*                          </div>*/}
                      {/*                      </div>*/}
                      {/*                      <h2 className="mt-0">*/}
                      {/*                          <i className="mdi mdi-check-all"/>*/}
                      {/*                      </h2>*/}
                      {/*                      <h3 className="mt-0">Finish</h3>*/}

                      {/*                      <div className="row form-group">*/}
                      {/*                        <div className="col-md-12">*/}
                      {/*                          <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>*/}
                      {/*                        </div>*/}
                      {/*                      </div>*/}

                      {/*                  </div>*/}
                      {/*              </div>*/}
                      {/*          </div>*/}

                      {/*  </div>*/}

                        <ul className="list-inline wizard mb-0">
                            {/*<li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a></li>*/}
                            {/*<li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>Next</a></li>*/}
                            {/*<li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>*/}
                            <li className="next list-inline-item float-end">
                                <a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()}>
                                    Create Game
                                </a>
                            </li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
