import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {appDatabasePrimaryFunctions, base} from "../../base";
import {ErrorMessage, Toast, WarningMessage} from "../utils/HelpfulFunction";

class SendRewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rewardsList: [],
            userCheckedInCount: [],
            loading: true
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.getRewards();
      this.userCheckedInCountRef = base.listenTo('userCheckedIn', {
          context: this,
          queries: {
              orderByChild: 'checkedIn',
              equalTo: true
          },
          asArray: true,
          then(userCheckedInArray){
              this.setState({userCheckedInArray: userCheckedInArray})
          }
      })
    }

    componentWillUnmount() {
        base.removeBinding(this.userCheckedInCountRef);
    }

    getRewards(){
        const urlToGet = process.env.REACT_APP_REWARD_URL + 'coupons';
        fetch(urlToGet)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    loading: false,
                    rewardsList: data.data || []
                })
            })
            .catch(e => {
                console.log("ERROR", e)
                this.setState({loading: false})
            })
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    async sendRewards(){
        let selectedReward = false;
        const rewardsList = this.state.rewardsList || [];
        const rewardToSend = this.state.rewardToSend || 0;
        const userCheckedInArray = this.state.userCheckedInArray || [];
        for(const rewardIndex in rewardsList){
            const reward = rewardsList[rewardIndex];
            if(parseInt(reward.id) === parseInt(rewardToSend)){
                selectedReward = reward
            }
        }
        if(!rewardToSend || !selectedReward){
            await ErrorMessage.fire({
                title: 'Uh oh!',
                text: 'Select a reward to send'
            })
            return;
        }
        if(selectedReward.unique_codes.length > 0){
            await ErrorMessage.fire({
                title: 'Uh oh!',
                text: 'At this time unique codes rewards are not supported'
            })
            return;
        }
        if(userCheckedInArray.length < 1){
            await ErrorMessage.fire({
                title: 'Uh oh!',
                text: 'No One Checked In Yet'
            })
            return
        }
        if(parseInt(selectedReward.number_available) < userCheckedInArray.length){
            const warningResponse = await WarningMessage.fire({
                title: 'Warning!',
                text: 'Less reward amount then people checked in, proceed?',
                confirmButtonText: 'Yes'
            });
            if(!warningResponse.value){
                return
            }
        }
        const sendPrizesResponse = await WarningMessage.fire({
            title: 'Send Prize?',
            text: 'Are you sure? This will send an email to all fans checked in with this reward!',
            confirmButtonText: 'Yes'
        });
        if(!sendPrizesResponse.value){
            return
        }
        this.setState({loading: true});
        const emails = [];
        const users = [];
        const usersRef = await appDatabasePrimaryFunctions.ref('users').once('value');
        const couponEmailRef = await appDatabasePrimaryFunctions.ref('couponEmail').once('value');
        const uidToUsernameRef = await appDatabasePrimaryFunctions.ref('uidToUsername').once('value');
        const usernameToEmailRef = await appDatabasePrimaryFunctions.ref('usernameToEmail').once('value');
        const usersObject = usersRef.val();
        const couponEmailObject = couponEmailRef.val();
        const uidToUsernameObject = uidToUsernameRef.val();
        const usernameToEmailObject = usernameToEmailRef.val();
        for(let i = 0; i < userCheckedInArray.length; i++){
            const user = {};
            const userId = userCheckedInArray[i].key;
            user['userId'] = userId;
            let userObject = usersObject[userId] || {};
            let userEmail = userObject.email || "";
            if(!userEmail && couponEmailObject[userId]){
                userEmail = couponEmailObject[userId].email || "";
            }
            if(uidToUsernameObject[userId]){
                const username = uidToUsernameObject[userId].userName || "";
                user['username'] = username;
                if(username && usernameToEmailObject[username] && usernameToEmailObject[username].email && !userEmail){
                    userEmail = usernameToEmailObject[username].email;
                }
            }
            if(userEmail && emails.indexOf(userEmail) === -1){
                emails.push(userEmail)
                user['email'] = userEmail;
                users.push(user)
            }
        }
        if(emails.length > 0){
            this.sendEmails(users, selectedReward);
        } else {
            this.setState({loading: false});
            await Toast.fire({
                title: 'Emails sent'
            })
        }
    }

    sendEmails(users, reward){
        for(const userIndex in users){
            const user = users[userIndex];
            this.redeemCoupon(user, reward);
        }
        this.setState({loading: false});
        Toast.fire({
            title: 'Emails sent'
        })
    }

    redeemCoupon(user, reward){
        const urlToGet = process.env.REACT_APP_REWARD_URL + 'redeemed_coupons/admin_coupon_giveaway';
        let method = "POST";
        const jsonData = {
            "coupon_id": reward.id,
            "user_name": user.username,
            "user_id": user.userId,
            "email": user.email,
        };
        fetch(urlToGet, {method: method, body: JSON.stringify(jsonData), headers:{'Content-Type': 'application/json'}})
            .then(response => response.json())
            .then(data => {
                console.log(data)
            })
            .catch(e => {
                console.log(e)
            })
    }

    render() {
        const userCheckedInCount = this.state.userCheckedInArray || [];
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="container sidebar-container">
                    <div className="col-md-12" style={{color:'black'}}>
                        <p>Send Reward To Users</p>
                        <h6>WARNING:</h6>
                        <ol>
                            <li>You should only use this for rewards that are not being distributed in any other way</li>
                            <li>The reward you are sending CANNOT have codes</li>
                            <li>Not all users that are checked in have an email so they may not receive the reward</li>
                            <li>Make sure there is enough of an award to send it to people, if there aren't enough they
                                wont receive it
                            </li>
                        </ol>
                        <p>Rewards</p>
                        <div className="col-md-6">
                            <select name="rewardToSend" id="rewardToSend" className="form-control" onChange={this.handleChange}>
                                <option value="">----</option>
                                {this.state.rewardsList.length > 0 &&
                                    <>
                                        {
                                            this.state.rewardsList.map(function(item, index) {
                                                return(
                                                    <option value={item.id} key={index}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </select>
                        </div>
                        <h4>Checked In User Count: {userCheckedInCount.length}</h4>
                        <button className="btn btn-success" onClick={()=>this.sendRewards()}>
                            Send Rewards
                        </button>
                    </div>
                </div>
            </div>
         </div>
        );
    }
}

export default SendRewards
