import Rebase from 're-base';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import "firebase/compat/auth";

let databaseUrl = process.env.REACT_APP_FIREBASE_PROJECT_ID;
let dontConvertIds = ["anothertestdb-be6c5", "whawksv2", "checkersdbtest", "stormdbversion2"];
if(dontConvertIds.indexOf(process.env.REACT_APP_FIREBASE_PROJECT_ID) === -1){
  databaseUrl = process.env.REACT_APP_FIREBASE_PROJECT_ID + "-default-rtdb";
}

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROJECT_ID + ".firebaseapp.com",
  databaseURL: "https://" + databaseUrl + ".firebaseio.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_ID + ".appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_ID
};

const app = firebase.initializeApp(config);
const appDatabasePrimaryFunctions = firebase.database(app);
const base = Rebase.createClass(appDatabasePrimaryFunctions);

export { app, appDatabasePrimaryFunctions, base, firebase}
