import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import {app, appDatabasePrimaryFunctions, base, firebase} from '../../base';
import '../../styles/css/AdminMain.css';
import {Toast, ErrorMessage, WarningMessage} from '../utils/HelpfulFunction';
import BasicDropzone from '../utils/Dropzone';

class SetUpBadges extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            itemsList: [],
            editingItem: false,
            itemToEdit: null,
            imageUrl: null,
            modal: false,
            itemName: "",
            itemCode: "",
            editItemId: "",
            editImage: '',
            imageUrlPreview: {},
            loading:true,
            files: [],
            itemPoints: 0,
            foundUsersList: [],
            foundBadgesList: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getBadges(false);
    }

    async getBadges(withCodes=false){
        this.setState({loading: true})
        let result;
        if(!withCodes){
            result = await appDatabasePrimaryFunctions.ref('badges').orderByChild('secretCode').equalTo(null).once('value');
        } else {
            result = await appDatabasePrimaryFunctions.ref('badges').orderByChild('secretCode').startAt("\t").once('value');
        }
        let objectResult = result.val();
        let resultsArray = [];
        for(let objectIndex in objectResult){
            let badge = objectResult[objectIndex];
            if(!badge.id){
                badge.id = objectIndex;
            }
            resultsArray.push(badge);
        }
        this.setState({
            itemsList: resultsArray,
            loading: false
        })
        this.toggleKindOfBadge(withCodes, true);
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        ErrorMessage.fire({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type'
        })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
          if(res.error){
              ErrorMessage.fire({
                  title: 'Image cannot be uploaded',
                  text: res.error,
              })
          } else if(res.imageUrl) {
              const nameToUpdate = myArgument + "Preview"
              const fileToUpdate = files[0]
              this.setState({
                  [myArgument]: res.imageUrl,
                  [nameToUpdate]: ({
                      fileToUpdate,
                      preview: URL.createObjectURL(fileToUpdate)
                  })
              });
          } else {
              ErrorMessage.fire({
                  title: 'Image cannot be uploaded',
                  text: 'Something went wrong, please re-upload your image and try again!'
              })
          }
      })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.imageUrlPreview.preview);
      // base.removeBinding(this.badgesListRef);
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        if(this.state.loading){
            return
        }
        const name = this.state.itemName || "";
        const code = this.state.itemCode || null;
        let points = this.state.itemPoints || 0;
        points = parseInt(points);
        if(!name || name.length > 50){
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Name cannot be longer than 50 characters or be left blank!',
            })
            return;
        }
        const badgeImage = this.state.imageUrl || this.state.editImage || "";
        const vm = this;
        if(!points || !badgeImage || points <= 0){
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again and make sure points are greater than 0'
            })
            return;
        }
        const itemToSave = {}
        itemToSave['name'] = name;
        itemToSave['points'] = points;
        itemToSave['url'] = badgeImage;
        itemToSave['secretCode'] = code;
        this.setState({loading:true})
        let oldSecretCode;
        itemToSave['id'] = (this.state.itemToEdit && (this.state.itemToEdit.id || this.state.itemToEdit.key)) || appDatabasePrimaryFunctions.ref().push().key;
        if(code && !this.state.itemToEdit){
           let result = await appDatabasePrimaryFunctions.ref('badges').orderByChild('secretCode').equalTo(code).once('value');
           if(result.exists()){
               this.setState({loading:false})
               ErrorMessage.fire({
                   title: 'Uh oh!',
                   text: 'A badge with this secret code already exists'
               })
               return;
           }
            itemToSave['id'] = code;
        } else if(code){
            itemToSave['id'] = code;
            oldSecretCode = this.state.itemToEdit.secretCode;
        }
        base.post(`badges/${itemToSave['id']}`, {
            data: itemToSave,
            then(err){
                if(!err){
                    Toast.fire({
                        title: vm.state.itemToEdit?'Edited Successfully!':'Created Successfully!'
                    })
                    if(vm.state.itemToEdit && oldSecretCode){
                        appDatabasePrimaryFunctions.ref('badges').child(oldSecretCode).remove()
                    }
                    vm.setState({
                        fileEmailImage: null,
                        itemName: "",
                        emailImage: null,
                        itemPoints: "",
                        itemCode: "",
                        itemToEdit: null,
                        imageUrlPreview: {},
                        editingItem: false,
                        modal: false,
                        loading:false
                    })
                    vm.getBadges(!!code);
                } else {
                    vm.setState({loading:false});
                    ErrorMessage.fire({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in'
                    })
                    console.log(err)
                }
            }
        })
    }

    editItem(event){
      event.preventDefault();
      const array = this.state.itemsList;
      const item = array[event.target.value];
      this.setState({
          modal: true,
          itemName: item.name,
          itemPoints: item.points,
          itemCode:item.secretCode,
          editImage:item.url,
          itemToEdit: item,
          editingItem: true
      });
    }

    async deleteItem(e){
      e.preventDefault();
      const array = this.state.itemsList;
      const item = array[e.target.value];
      let itemId = item.id || item.key;
      if(item.secretCode){
          itemId = item.secretCode;
      }
      const response = await WarningMessage.fire({
          title: 'Delete?',
          text: 'Are you sure you want to do this?  You will no longer be able to use this anymore',
          confirmButtonText: 'Delete'
      });
      if(response.value){
          const vm = this;
          base.remove('badges/' + itemId, function(err) {
              if(err){
                  ErrorMessage.fire({
                      title: 'There was some error!',
                      text: 'Try again and if the problem persists try logging out and logging back in',
                  })
              } else {
                  vm.getBadges(!!item.secretCode);
              }
          })
      }
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        itemName: "",
        itemPoints: "",
        itemCode: "",
        imageUrl: null,
        imageUrlPreview: {},
        emailImage:null,
        itemToEdit: null,
        editingItem: false
      });
    }

    toggleKindOfBadge(withCodes, fromGetBadges=false){
        let noCodesElement = document.getElementById('showNoCodeBadges');
        let codesElement = document.getElementById('showCodeBadges');
        let sendBadgesElement = document.getElementById('showSendBadgesToUsers');
        if(withCodes === false || withCodes === true){
            if(withCodes === false){
                codesElement.classList.remove('active');
                sendBadgesElement.classList.remove('active');
                noCodesElement.classList.add('active');
            } else {
                codesElement.classList.add('active');
                sendBadgesElement.classList.remove('active');
                noCodesElement.classList.remove('active');
            }
            if(!fromGetBadges){
                this.getBadges(withCodes)
            }
            this.setState({
                showUsersSection: false
            })
        } else {
            codesElement.classList.remove('active');
            sendBadgesElement.classList.add('active');
            noCodesElement.classList.remove('active');
            this.setState({
                showUsersSection: true
            })
        }
    }

    async findUsersWithUserName(search){
        const result = await appDatabasePrimaryFunctions.ref('uidToUsername').orderByChild('userName').startAt(search).endAt(search+"\uf8ff").limitToFirst(5).once('value');
        let resultsArray = [];
        let finalResult = result.val();
        for(const i in finalResult){
            resultsArray.push(finalResult[i]);
        }
        this.setState({
            foundUsersList: resultsArray
        })
    }

    async findBadgesWithName(search){
        const result = await appDatabasePrimaryFunctions.ref('badges').orderByChild('name').startAt(search).endAt(search+"\uf8ff").limitToFirst(5).once('value');
        let resultsArray = [];
        let finalResult = result.val();
        for(const i in finalResult){
            resultsArray.push(finalResult[i]);
        }
        this.setState({
            foundBadgesList: resultsArray
        })
    }

    async giveBadgeToUser(){
        this.setState({loading: true})
        const userName = document.getElementById("userName").value;
        const badge = document.getElementById('badgeToGive').value;
        const userToGive = await appDatabasePrimaryFunctions.ref('uidToUsername').orderByChild('userName').equalTo(userName).limitToFirst(1).once('value');
        const badgeToGive = await appDatabasePrimaryFunctions.ref('badges').orderByChild('name').equalTo(badge).limitToFirst(1).once('value');
        if(!userToGive.exists() || !badgeToGive.exists()){
            ErrorMessage.fire({
                title: "Error",
                text: "User or badge not found",
            });
            this.setState({loading: false})
            return;
        }
        let userId;
        userToGive.forEach(function (data){
            userId = data.key;
        });
        if(!userId){
            ErrorMessage.fire({
                title: "Error",
                text: "User doesn't exist",
            });
            this.setState({loading: false})
            return;
        }
        let loadedBadge;
        let nameToSaveBy;
        let badgeId;
        badgeToGive.forEach(function (data){
            loadedBadge = data.val();
            nameToSaveBy = loadedBadge.id || data.key;
            badgeId = loadedBadge.id || data.key;
        })
        if(loadedBadge.secretCode){
            nameToSaveBy = loadedBadge.secretCode;
        }
        loadedBadge['id'] = badgeId;
        loadedBadge['timeRedeemed'] = base.timestamp;
        const result = await appDatabasePrimaryFunctions.ref('userBadges').child(userId + "/" + nameToSaveBy).once('value');
        if(result.exists()){
            ErrorMessage.fire({
                title: "Error",
                text: "User already has this badge",
            });
            this.setState({loading: false})
            return;
        }
        let objectToPush = {};
        objectToPush['userPoints/' + userId + "/points"] = firebase.database.ServerValue.increment(loadedBadge.points);
        objectToPush['userLevel/' + userId + "/totalPoints"] = firebase.database.ServerValue.increment(loadedBadge.points);
        objectToPush['userBadges/' + userId + "/" + nameToSaveBy] = loadedBadge;
        await appDatabasePrimaryFunctions.ref().update(objectToPush);
        this.setState({loading: false})
        Toast.fire({
            icon: 'success',
            title: 'Badge Given Successfully!'
        })
    }

    render() {
        const {loading, itemsList=[], imageUrlPreview={}, foundUsersList=[], foundBadgesList=[]} = this.state;
        const vm = this;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Badges</p>
                    <p className="admin-subheader-text">These are badges fans will receive when completing certain tasks</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Badge</button>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                        <li className="nav-item" onClick={()=> this.toggleKindOfBadge(false)}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showNoCodeBadges">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">No Secret Code</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.toggleKindOfBadge(true)}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showCodeBadges">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Secret Code</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.toggleKindOfBadge()}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showSendBadgesToUsers">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Give Badge</span>
                            </a>
                        </li>
                    </ul>
                    {this.state.showUsersSection ?
                           <div>
                               <h3>
                                   Give a badge to a user
                               </h3>
                               <span style={{color:"black"}}>Please enter EXACTLY the User Name and Badge Name that you want given</span>
                               <div className="form-group col-lg-4">
                                   <label htmlFor="userName">User Name</label>
                                   <input autoComplete="off" id="userName" name="userName" type="text" className="form-control" value={this.state.userName} onChange={()=>{
                                       clearTimeout(this.timeoutId); // no-op if invalid id
                                       this.timeoutId = setTimeout(function(){
                                           let input = document.getElementById('userName').value;
                                           if(input){
                                               vm.findUsersWithUserName(input);
                                           } else {
                                               vm.setState({
                                                   foundUsersList: []
                                               })
                                           }
                                       }, 500);
                                   }} placeholder="User Name"/>
                                   {foundUsersList && foundUsersList.length > 0 &&
                                       <ul style={{backgroundColor: "white", color: "black", outline: "1px solid black"}}>
                                           {foundUsersList.map(function (found, index) {
                                               return <li key={index} onClick={()=>{
                                                   document.getElementById("userName").value = found.userName; vm.setState({foundUsersList: []})
                                               }}>
                                                   {found.userName}
                                               </li>
                                           })}
                                       </ul>
                                   }
                               </div>
                               <div className="form-group col-lg-4">
                                   <label htmlFor="badgeToGive">Badge</label>
                                   <input autoComplete="off" id="badgeToGive" name="badgeToGive" type="text" className="form-control" value={this.state.badgeToGive} onChange={()=>{
                                       clearTimeout(this.timeoutId); // no-op if invalid id
                                       this.timeoutId = setTimeout(function(){
                                           let input = document.getElementById('badgeToGive').value;
                                           if(input){
                                               vm.findBadgesWithName(input);
                                           } else {
                                               vm.setState({
                                                   foundBadgesList: []
                                               })
                                           }
                                       }, 500);
                                   }} placeholder="Badge Name"/>
                                   {foundBadgesList && foundBadgesList.length > 0 &&
                                       <ul style={{backgroundColor: "white", color: "black", outline: "1px solid black"}}>
                                           {foundBadgesList.map(function (foundBadge, index) {
                                               return <li key={index} onClick={()=>{
                                                   document.getElementById("badgeToGive").value = foundBadge.name;
                                                   vm.setState({
                                                       foundBadgesList: []
                                                   })
                                               }}>
                                                   {foundBadge.name}
                                               </li>
                                           })}
                                       </ul>
                                   }
                               </div>
                               <div className="col-lg-3">
                                   <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginTop:20}} onClick={() => this.giveBadgeToUser()}>Give Badge</button>
                               </div>
                           </div>
                        :
                        <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                            {
                                itemsList.map(function(item,i){
                                    if(!item.name){
                                        return;
                                    }
                                    return <div key={i} className="card">
                                        <div className="card-body" align="center">
                                            <p style={{marginTop:5}}>{item.name}</p>
                                            <p>
                                                <img width="80%" height="auto" src={item.url} alt=""/>
                                            </p>
                                            <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editItem.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                            <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteItem.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                                        </div>
                                    </div>
                                }, this)
                            }
                        </div>
                    }
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add Badge</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group">
                            <label htmlFor="itemName">Name</label>
                            <input id="itemName" name="itemName" type="text" className="form-control" value={this.state.itemName} onChange={this.handleChange} placeholder="Badge Name"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="itemPoints">Points: </label>
                            <input id="itemPoints" name="itemPoints" type="number" className="form-control" value={this.state.itemPoints} onChange={this.handleChange} placeholder="10"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="itemCode">Code (Only numbers and lowercase letters, no spaces)</label>
                            <input pattern="[a-zA-Z0-9]*" id="itemCode" name="itemCode" type="text" className="form-control" value={this.state.itemCode} onChange={this.handleChange} placeholder="itemcode1"/>
                        </div>
                        <div className="form-group" align="center">
                          <label htmlFor="imageUrl" style={{width:'100%'}}>
                              Image (200px X 200px)
                              <span style={{cursor:"pointer", display: imageUrlPreview.preview || this.state.imageUrl?"":"none"}} onClick={()=>this.setState({editImage: null, fileUploaded:false, imageUrlPreview: {}, imageUrl:null})}>
                              ❌
                          </span>
                          </label>
                          <img src={this.state.editImage} width="auto" height="100px" style={{display: this.state.editingItem ? '' : 'none' }} alt=""/>
                          <BasicDropzone
                              onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "imageUrl")}
                              preview={imageUrlPreview.preview}
                          />
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpBadges
