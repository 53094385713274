import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import BasicDropzone from "../utils/Dropzone";
import { ErrorMessage } from "../utils/HelpfulFunction";
import UploadImage from "../utils/UploadImage";

class SetUpSettings extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            hideScoreboard: false,
            loading: true,
            imageUrlPreview: {},
            settings: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        this.settingsRef = base.syncState(`settings`, {
            context: this,
            state: 'settings',
        });
        this.setState({ loading: false });
    }

    componentWillUnmount() {
        base.removeBinding(this.settingsRef);
    }

    onDrop(files, rejected, fieldName) {
        if (rejected.length > 0) {
            ErrorMessage.fire({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type'
            });
            return;
        }
        this.setState({ loading: true });

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({ loading: false });
            if (res.error) {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                });
            } else if (res.imageUrl) {
                this.setState({
                    settings: {
                        ...this.state.settings,
                        [fieldName]: res.imageUrl
                    },
                    imageUrlPreview: {
                        ...this.state.imageUrlPreview,
                        [fieldName]: {
                            file: files[0],
                            preview: URL.createObjectURL(files[0])
                        }
                    }
                });
            } else {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: 'Something went wrong, please re-upload your image and try again!'
                });
            }
        });
    }

    handleChange(evt) {
        const { name, type, checked, value } = evt.target;
        const settings = { ...this.state.settings, [name]: type === 'checkbox' ? checked : value };
        this.setState({ settings });
    }

    renderImageUpload(label, fieldName) {
        const preview = this.state.imageUrlPreview[fieldName]?.preview;
        const settings = this.state.settings || {};
        const savedVersion = settings[fieldName]

        return (
            <div className="form-group" align="center">
                <label htmlFor={fieldName} style={{ width: '100%' }}>
                    {label}
                    <span
                        style={{ cursor: "pointer", display: preview || savedVersion ? "" : "none" }}
                        onClick={() => this.setState({
                            settings: {
                                ...this.state.settings,
                                [fieldName]: null
                            },
                            imageUrlPreview: {
                                ...this.state.imageUrlPreview,
                                [fieldName]: {}
                            }
                        })}
                    >
            ❌
          </span>
                </label>
                <img
                    src={preview || savedVersion}
                    width="auto"
                    height="100px"
                    style={{ display: preview || savedVersion ? '' : 'none' }}
                    alt=""
                />
                <BasicDropzone
                    onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, fieldName)}
                    preview={preview}
                />
            </div>
        );
    }

    render() {
        const { settings } = this.state;
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Settings</p>
                    <div className="admin-form-box">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="hideScoreboard">Hide Scoreboard</label>
                            <br />
                            <label className="switch" style={{ width: 60 }}>
                                <input
                                    name="hideScoreboard"
                                    id="hideScoreboard"
                                    type="checkbox"
                                    checked={settings.hideScoreboard || false}
                                    onChange={this.handleChange}
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="supportEmail">Support Email</label>
                            <br />
                            <input
                                value={this.state.settings.supportEmail}
                                name="supportEmail"
                                id="supportEmail"
                                type="email"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="minimumNumberOfSecondsSplashScreen">Minimum Number of Seconds of Splash Screen</label>
                            <br />
                            <input
                                value={this.state.settings.minimumNumberOfSecondsSplashScreen}
                                name="minimumNumberOfSecondsSplashScreen"
                                id="minimumNumberOfSecondsSplashScreen"
                                type="number"
                                min={1}
                                onChange={this.handleChange}
                            />
                        </div>
                        {this.renderImageUpload("Background Image (1125x2001)", "backgroundImage")}
                        {this.renderImageUpload("Top Bar Icon", "topBarIcon")}
                        {this.renderImageUpload("Menu Icon", "menuIcon")}
                        {this.renderImageUpload("Side Menu Top Image", "sideMenuTopImage")}
                        {this.renderImageUpload("Splash Screen", "splashScreen")}
                        {this.renderImageUpload("Auth Flow Image", "authFlowImage")}
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpSettings;
