import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {base} from '../../base';
import '../../styles/css/AdminMain.css';

class SetUpRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            streamUrl: "",
            active: false,
            loading: true
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.radioRef = base.syncState(`radio`, {
            context: this,
            state: 'radio',
        });
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.radioRef);
    }

    handleChange (evt) {
        let radioCopy = this.state.radio;
        let target = evt.target;
        radioCopy[target.name] = target.type === 'checkbox' ? target.checked : target.value;
        radioCopy[target.name] = target.value
        this.setState({radio: radioCopy});
    }

    render() {
        const {radio={}} = this.state;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0}}>Radio</p>
                <div className="admin-form-box">
                    <div className="form-group">
                      <label htmlFor="streamUrl">Stream URL</label>
                        <p style={{fontSize:12,fontWeight:"normal",color:'grey'}}>https urls are preferred but http urls can work; you may need to communicate with Sqwad if the http link doesn't work. Don't forget to test on both iOS and Android to make sure the link is working</p>
                      <input type="url" id="streamUrl" name="streamUrl" className="form-control" value={radio.streamUrl} onChange={this.handleChange} placeholder=""/>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="userName3">{radio.active?"On":"Off"}</label>
                        <br/>
                        <label className="switch" style={{ width: 60}}>
                            <input name="active" id="active" type="checkbox" value={radio.active} checked={radio.active} onClick={()=>this.setState({radio: {
                                active: !radio.active
                                }})}/>
                            <span className="slider round"/>
                        </label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="popUpMessage">Pop Up Message</label>
                        <p style={{fontSize:12,fontWeight:"normal",color:'grey'}}>The message that pops up when the radio is not active (optional)</p>
                        <input type="url" id="popUpMessage" name="popUpMessage" className="form-control" value={radio.popUpMessage} onChange={this.handleChange} placeholder=""/>
                    </div>
                </div>
            </div>
         </div>
        );
    }
}

export default SetUpRadio;
