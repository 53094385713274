import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import {appDatabasePrimaryFunctions, base} from '../../base';
import '../../styles/css/AdminMain.css';
import {Toast, ErrorMessage, WarningMessage} from '../utils/HelpfulFunction';
import BasicDropzone from '../utils/Dropzone';

class SetUpPlayers extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            itemsList: [],
            editingItem: false,
            itemToEdit: null,
            imageUrl: null,
            modal: false,
            itemName: "",
            itemNumber: "",
            editItemId: "",
            editImage: '',
            imageUrlPreview: {},
            loading:true,
            files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.playersListRef = base.syncState(`players`, {
            context: this,
            state: 'itemsList',
            asArray: true,
            then() {
                this.setState({loading: false})
            },
            catch(e){
                this.setState({loading: false})
            }
        })

        this.playerStatsRef = base.bindToState(`playerStats`, {
            context: this,
            state: 'playerStats',
            asArray: true,
            then() {
                this.setState({loading: false})
            },
            catch(e){
                this.setState({loading: false})
            }
        })
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        ErrorMessage.fire({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type'
        })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
          if(res.error){
              ErrorMessage.fire({
                  title: 'Image cannot be uploaded',
                  text: res.error,
              })
          } else if(res.imageUrl) {
              const nameToUpdate = myArgument + "Preview"
              const fileToUpdate = files[0]
              this.setState({
                  [myArgument]: res.imageUrl,
                  [nameToUpdate]: ({
                      fileToUpdate,
                      preview: URL.createObjectURL(fileToUpdate)
                  })
              });
          } else {
              ErrorMessage.fire({
                  title: 'Image cannot be uploaded',
                  text: 'Something went wrong, please re-upload your image and try again!'
              })
          }
      })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.imageUrlPreview.preview);
      base.removeBinding(this.playersListRef);
      base.removeBinding(this.playerStatsRef);
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        if(this.state.loading){
            return
        }

        const name = this.state.itemName || "";
        let itemNumber = this.state.itemNumber || 0;
        if(!name || name.length > 50){
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Name cannot be longer than 50 characters or be left blank!',
            })
            return;
        }
        const image = this.state.imageUrl || this.state.editImage || "";
        const vm = this;
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "anothertestdb-be6c5";
        const isWinterhawks = process.env.REACT_APP_FIREBASE_PROJECT_ID === "whawksv2";
        if(!isWinterhawks) itemNumber = parseInt(itemNumber);
        if(!itemNumber && itemNumber !== 0){
            ErrorMessage.fire({
                title: 'Number Not Entered',
                text: 'Please enter them and try again'
            })
            return;
        }

        if(this.state.playerStats && this.state.playerStats.length > 0 && typeof itemNumber === "string"){
            const splitItemNumber = itemNumber.split(" ");
            const numbersInPlayerStatsArray = [];
            for(const i in this.state.playerStats){
                const playerStat = this.state.playerStats[i];
                numbersInPlayerStatsArray.push(parseInt(playerStat.number));
            }
            for(const i in splitItemNumber){
                if(numbersInPlayerStatsArray.indexOf(parseInt(splitItemNumber[i])) === -1){
                    const response = await WarningMessage.fire({
                        title: 'Continue?',
                        text: `The number ${splitItemNumber[i]} was not found in the player list. Are you sure you want to add this player?`,
                        confirmButtonText: 'Continue'
                    });
                    if(!response || !response.value){
                        return
                    }
                }
            }
        }

        for(const i in this.state.itemsList){
            const player = this.state.itemsList[i];
            const isEditingANdIsPlayerToEdit = this.state.itemToEdit && this.state.itemToEdit.id === player.id;
            if((player.name === name || player.number === itemNumber) && !isEditingANdIsPlayerToEdit){
                ErrorMessage.fire({
                    title: 'Input Error',
                    text: 'Name and number must be unique!'
                })
                return
            }
        }
        const itemToSave = {}
        itemToSave['name'] = name;
        itemToSave['number'] = itemNumber;
        itemToSave['url'] = image;
        itemToSave['assists'] = 0;
        itemToSave['goals'] = 0;
        itemToSave['goodPlays'] = 0;
        this.setState({loading:true})
        itemToSave['id'] = (this.state.itemToEdit && this.state.itemToEdit.key) || appDatabasePrimaryFunctions.ref().push().key;
        base.post(`players/${itemToSave['id']}`, {
            data: itemToSave,
            then(err){
                if(!err){
                    Toast.fire({
                        title: vm.state.itemToEdit?'Edited Successfully!':'Created Successfully!'
                    })
                    vm.setState({
                        fileEmailImage: null,
                        itemName: "",
                        emailImage: null,
                        itemNumber: "",
                        itemToEdit: null,
                        imageUrlPreview: {},
                        editingItem: false,
                        modal: false,
                        loading:false
                    })
                } else {
                    vm.setState({loading:false});
                    ErrorMessage.fire({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in'
                    })
                    console.log(err)
                }
            }
        })
    }

    editItem(event){
      event.preventDefault();
      const array = this.state.itemsList;
      const item = array[event.target.value];
      this.setState({
          modal: true,
          itemName: item.name,
          itemNumber: item.number,
          editImage:item.url,
          itemToEdit: item,
          editingItem: true
      });
    }

    async deleteItem(e){
      e.preventDefault();
      const array = this.state.itemsList;
      const index = array[e.target.value];
      const response = await WarningMessage.fire({
          title: 'Delete?',
          text: 'Are you sure you want to do this?  You will no longer be able to use this anymore',
          confirmButtonText: 'Delete'
      });
      if(response.value){
          base.remove('players/' + index.key, function(err) {
              if(err){
                  ErrorMessage.fire({
                      title: 'There was some error!',
                      text: 'Try again and if the problem persists try logging out and logging back in',
                  })
              }
          })
      }
    }

    toggle() {
      this.setState({
          modal: !this.state.modal,
          itemName: "",
          itemNumber: 0,
          editImage:"",
          itemToEdit: null,
          editingItem: false
      });
    }

    render() {
        const {loading, itemsList=[], imageUrlPreview={}} = this.state;
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "anothertestdb-be6c5";
        const isWinterhawks = process.env.REACT_APP_FIREBASE_PROJECT_ID === "whawksv2";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Players</p>
                    <p className="admin-subheader-text">These are players fans will pick to earn points</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Player</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                        {
                            itemsList.map(function(item,i){
                                if(!item.name){
                                    return;
                                }
                                return <div key={i} className="card">
                                    <div className="card-body" align="center">
                                        <p style={{marginTop:5}}>{item.name}</p>
                                        <p style={{marginTop:5}}>{item.number}</p>
                                        <p>
                                            <img width="80%" height="auto" src={item.url} alt=""/>
                                        </p>
                                        <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editItem.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                        <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteItem.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                                    </div>
                                </div>
                            }, this)
                        }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add Players</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group">
                            <label htmlFor="itemName">Name</label>
                            <input id="itemName" name="itemName" type="text" className="form-control" value={this.state.itemName} onChange={this.handleChange} placeholder="Name"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="itemNumber">Number: {(isWinterhawks || isDev) && "(if multiple numbers separate with space)"}</label>
                            <input id="itemNumber" name="itemNumber" type={(isWinterhawks || isDev) ? "text" : "number"} className="form-control" value={this.state.itemNumber} onChange={this.handleChange} placeholder="10"/>
                        </div>
                          {!(isWinterhawks || isDev) &&
                              <div className="form-group" align="center">
                                  <label htmlFor="imageUrl" style={{width:'100%'}}>
                                      Image (690px X 381px)
                                      <span style={{cursor:"pointer", display: imageUrlPreview.preview || this.state.imageUrl?"":"none"}} onClick={()=>this.setState({editImage: null, fileUploaded:false, imageUrlPreview: {}, imageUrl:null})}>
                              ❌
                          </span>
                                  </label>
                                  <img src={this.state.editImage} width="auto" height="100px" style={{display: this.state.editingItem ? '' : 'none' }} alt=""/>
                                  <BasicDropzone
                                      onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "imageUrl")}
                                      preview={imageUrlPreview.preview}
                                  />
                              </div>
                          }
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpPlayers
