import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {app, base} from '../../base';
import '../../styles/css/AdminMain.css';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ErrorMessage, Toast, WarningMessage} from "../utils/HelpfulFunction";

class AdminManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adminsList: [],
            loading: true,
            modal: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        base.bindToState('roles', {
            context: this,
            asArray: true,
            state: 'adminList',
            then(){
                this.setState({
                    loading: false
                })
            },
            onFailure(e){
               console.log(e);
                this.setState({
                    loading: false
                })
            }
        })
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            createNewEmail: "",
            createNewPassword: ""
        });
    }

    handleChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[target.name]: value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        if(this.state.loading) return;
        const email = this.state.createNewEmail || "";
        const password = this.state.createNewPassword || "";
        const idToken = await app.auth().currentUser.getIdToken(true);
        if(!email || !password){
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Need a password and an email',
            })
            return;
        }

        const userResponse = {};
        userResponse['email'] = email;
        userResponse['password'] = password;
        userResponse['role'] = 'admin';
        userResponse['changePasswordIfExists'] = this.state.changePasswordIfExists || false;
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/createOrUpdateAdminUser`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/createOrUpdateAdminUser`;
        }
        this.setState({loading: true});
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${idToken}`
            },
            body: JSON.stringify(userResponse),
        }).then(res => {
            const vm = this;
            Promise.resolve(res.json()).then(function (value) {
                console.log(value)
                if(!value || value.message === "Unauthorized"){
                    ErrorMessage.fire({
                        title: 'Oh uh!',
                        text: 'Auth or request error; logout and log back in',
                    })
                }
                Toast.fire({
                    title: 'Admin Created'
                })
                vm.setState({loading: false, modal: false});
            })
        }).catch(err => {
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Auth or request error; logout and log back in',
            })
            this.setState({loading: false});
        })
    }

    async deleteAdmin(uid){
        if(!uid){
            return;
        }
        const result = await WarningMessage.fire({
            title: 'STOP!',
            text: 'Are you sure you want to do this?  This will erase all information about this user.  If you do this the data will be IRRETRIEVABLE.',
            confirmButtonText: 'YES I WANT TO PERMANENTLY DELETE THIS DATA'
        })
        if(!result || !result.value) return;
        const userResponse = {};
        userResponse['uidToDelete'] = uid;
        userResponse['isAdmin'] = true;
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/deleteUser`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/deleteUser`;
        }
        const idToken = await app.auth().currentUser.getIdToken(true);
        this.setState({loading: true})
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${idToken}`
            },
            body: JSON.stringify(userResponse),
        }).then(res => {
            this.setState({loading:false})
            if(res.status === 500){
                ErrorMessage.fire({
                    title: 'Oh uh!',
                    text: 'Auth or request error; logout and log back in',
                })
                return;
            }
            Promise.resolve(res.json()).then(function (value) {
                if(!value || value.message === "Invalid Permissions") {
                    ErrorMessage.fire({
                        title: 'Oh uh!',
                        text: 'Auth or request error; logout and log back in',
                    })
                    return;
                }
                Toast.fire({
                    title: 'Admin Deleted'
                })
            })
        }).catch(err => {
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Auth or request error; logout and log back in',
            })
            this.setState({loading: false});
        })
    }

    render() {
        const {loading, adminList=[]} = this.state;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0}}>Admin Management</p>
                <p className="admin-subheader-text">Here you can manage admins</p>
                <div style={{marginLeft: 20}}>
                    <button className="btn btn-success" onClick={()=>this.setState({modal: true})}>Create Admin</button>
                </div>
                {adminList.length > 0 &&
                    <div style={{height:700, overflow:"auto"}}>
                        <table className="table table-striped" style={{color:'black', margin: 20}}>
                            <tbody>
                            <tr>
                                <th>Email</th>
                                <th>Delete</th>
                            </tr>
                            {
                                adminList.map(function (item, i) {
                                    return <tr key={i}>
                                        <td style={{fontFamily:'Open Sans' }}>{item.email}</td>
                                        <td style={{fontFamily:'Open Sans'}}>
                                            <button className="btn btn-danger" onClick={()=>this.deleteAdmin(item.key)}>
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                }, this)
                            }
                            </tbody>
                        </table>
                    </div>
                }
              </div>
              <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
                  <ModalHeader>Create Admin</ModalHeader>
                  <ModalBody>
                      <div className="container-out">
                          <div className="question-box question-form">
                              <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-admin-form">
                                  <div className="form-group">
                                      <label htmlFor="createNewEmail">Email</label>
                                      <input id="createNewEmail" name="createNewEmail" type="email" className="form-control" value={this.state.createNewEmail} onChange={this.handleChange} placeholder="Email"/>
                                  </div>
                                  <div className="form-group">
                                      <label htmlFor="createNewPassword">Password</label>
                                      <input id="createNewPassword" name="createNewPassword" type="text" className="form-control" value={this.state.createNewPassword} onChange={this.handleChange} placeholder="password"/>
                                  </div>
                                  <div className="form-check">
                                      <label htmlFor="changePasswordIfExists">Change Password If Account Exists?</label>
                                      <input type="checkbox" className="form-check-input" value={this.state.changePasswordIfExists} onChange={this.handleChange}/>
                                  </div>
                                  <div style={{padding: 20, textAlign: "center"}}>
                                      <button className="btn btn-success">Submit</button>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </ModalBody>
              </Modal>
         </div>
        );
    }
}

export default AdminManagement
