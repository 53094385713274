import React from 'react';
import '../../styles/css/PreviewHomeScreenButtons.css'; // Make sure to import your CSS file

const Preview = ({ imagePreviews, backgroundImage }) => {
    return (
        <div className="preview-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            {imagePreviews.length > 0 ? (
                imagePreviews.map((image, index) => (
                    <div key={index} className="image-wrapper">
                        <img
                            src={image.image}
                            alt={`Preview ${index + 1}`}
                            className="preview-image"
                        />
                    </div>
                ))
            ) : (
                <p>No images uploaded</p>
            )}
        </div>
    );
};

export default Preview;
