import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome'
import AdminLogin from './components/admin/AdminLogin'
import SetUpRewards from './components/admin/SetUpRewards';
import SendPrizes from './components/admin/SendRewards';
import SetUpBadges from './components/admin/SetUpBadges';
import SetUpGame from './components/admin/SetUpGame';
import SetUpPlayers from './components/admin/SetUpPlayers';
import SetUpPoints from './components/admin/SetUpPoints';
import SetUpCarousel from './components/admin/SetUpCarousel';
import SetUpPushNotifications from './components/admin/SetUpPushNotifications';
import SetUpRadio from "./components/admin/SetUpRadio";
import UserManagement from "./components/admin/UserManagement";
import UserInfo from "./components/admin/UserInfo";
import AdminManagement from "./components/admin/AdminManagement";
import SetUpSettings from "./components/admin/SetUpSettings";
import SetUpHomeScreen from "./components/admin/SetUpHomeScreen";
import SetUpSideMenu from "./components/admin/SetUpSideMenu";
import { app } from './base';
import Swal from 'sweetalert2';

function PrivateRoute({ authenticated, children }) {
    return authenticated === true ? children : <Navigate to="/adminlogin" />;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true,
        }
    }

    setCurrentUser(user) {
        if (user) {
            this.setState({
                currentUser: user,
                authenticated: true
            })
        } else {
            this.setState({
                currentUser: null,
                authenticated: false
            })
        }
    }

    componentDidMount() {
        this.removeAuthListener = app.auth().onAuthStateChanged(async (user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false,
                })
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false,
                })
            }
        })
    }

    componentWillUnmount() {
        this.removeAuthListener()
    }

    async noPermissionResponse(){
        await Swal.fire({
            title: 'Uh Oh',
            text: "Your credentials do not give you permission to view this information, logging you out",
            type: 'warning',
            confirmButtonText: 'Ok'
        })
        await app.auth().signOut();
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        return (
            <BrowserRouter>
                <Routes>
                    <Route
                        path={`adminlogin`}
                        element={<AdminLogin setCurrentUser={this.setCurrentUser} user={this.state.currentUser} />}
                    />

                    <Route
                        path="/admin"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <AdminHome noPermissionResponse={()=>this.noPermissionResponse()}/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setupgame"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpGame />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setuptickets"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpRewards />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/sendprizes"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SendPrizes />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/setupbadges"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpBadges />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/manageplayers"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpPlayers />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/pointvalues"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpPoints />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/topcarousel"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpCarousel userData={this.state.currentUser}/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/pushnotifications"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpPushNotifications />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/radio"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpRadio />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/usermanagement"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <UserManagement />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/adminmanagement"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <AdminManagement />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/userinfo"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <UserInfo userData={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/settings"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpSettings userData={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/homebuttons"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpHomeScreen userData={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/sidemenubuttons"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated} >
                                <SetUpSideMenu userData={this.state.currentUser} />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="*"
                        element={<Navigate to="/adminlogin" replace />}
                    />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
