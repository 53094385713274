import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions, base } from '../../base';
import '../../styles/css/AdminMain.css';
import { Toast, ErrorMessage, WarningMessage } from '../utils/HelpfulFunction';
import SideMenuPreview from "../utils/PreviewMenu";

class SetUpSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsList: [],
            loading: true,
            icon: '',
            reservedIds: ['deleteAccount', 'privacyPolicy', 'logOut'],
            backgroundImage: '',
            localItemsList: [],
            hasUnsavedChanges: false // New state property to track unsaved changes
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveLocalStateToDatabase = this.saveLocalStateToDatabase.bind(this);
        this.handleLocalChange = this.handleLocalChange.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    }

    async componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        const defaultItems = [
            { header: 'Delete Account', buttonType: 'isDeleteButton', order: 1, id: 'deleteAccount' },
            { header: 'Privacy Policy', buttonType: 'isWebsiteLink', order: 2, id: 'privacyPolicy', link: 'https://www.sqwadhq.com/privacy-policy/' },
            { header: 'Logout', buttonType: 'isLogOut', order: 3, id: 'logOut' }
        ];
        this.sideMenuOptionsRef = base.listenTo(`sideMenuOptions`, {
            context: this,
            state: 'itemsList',
            query: {
                orderByChild: 'order'
            },
            asArray: true,
            then(data = []) {
                defaultItems.forEach(defaultItem => {
                    const defaultItemExists = data.some(item => item.id === defaultItem.id);
                    if (!defaultItemExists) {
                        base.post(`sideMenuOptions/${defaultItem.id}`, {
                            data: defaultItem
                        });
                    }
                });
                this.setState({
                    loading: false,
                    itemsList: data,
                    localItemsList: data // Initialize localItemsList with database data
                });
            },
            catch(e) {
                this.setState({ loading: false });
            }
        });

        const backgroundImage = await appDatabasePrimaryFunctions.ref('settings/backgroundImage').once('value');
        if (backgroundImage.exists()) {
            this.setState({
                backgroundImage: backgroundImage.val()
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        base.removeBinding(this.sideMenuOptionsRef);
    }

    handleBeforeUnload(event) {
        if (this.state.hasUnsavedChanges) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message; // Standard way to trigger a confirmation dialog in modern browsers
            return message;
        }
    }

    handleChange(event) {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    handleLocalChange(event) {
        let target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        const name = this.state.itemName || "";
        const itemLink = this.state.itemLink || "";
        const itemsList = this.state.localItemsList || [];
        const buttonType = this.state.buttonType;
        if (!name || name.length > 50) {
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Name cannot be longer than 50 characters or be left blank!',
            });
            return;
        }
        const icon = this.state.icon || "";
        const vm = this;
        if (!name) {
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again'
            });
            return;
        }
        const itemToSave = {};
        itemToSave['header'] = name;
        itemToSave['link'] = itemLink;
        itemToSave['icon'] = icon;
        itemToSave['buttonType'] = buttonType;
        itemToSave['order'] = (this.state.itemToEdit && this.state.itemToEdit.order) || itemsList.length + 1;
        this.setState({ loading: true });
        itemToSave['id'] = (this.state.itemToEdit && this.state.itemToEdit.key) || appDatabasePrimaryFunctions.ref().push().key;

        const updatedItemsList = [...itemsList];
        if (this.state.editingItem) {
            const index = updatedItemsList.findIndex(item => item.id === this.state.itemToEdit.id);
            updatedItemsList[index] = itemToSave;
        } else {
            updatedItemsList.push(itemToSave);
        }

        this.setState({
            localItemsList: updatedItemsList,
            itemName: "",
            itemLink: "",
            itemToEdit: null,
            editingItem: false,
            modal: false,
            loading: false,
            hasUnsavedChanges: true // Set hasUnsavedChanges to true when local changes are made
        });

        Toast.fire({
            title: this.state.itemToEdit ? 'Edited Locally!' : 'Created Locally!'
        });
    }

    editItem(event) {
        event.preventDefault();
        const array = this.state.localItemsList;
        const item = array[event.target.value];
        const isReservedId = this.state.reservedIds.indexOf(item.id) !== -1;
        this.setState({
            modal: true,
            itemName: item.header,
            itemLink: item.link,
            itemToEdit: item,
            icon: item.icon,
            editingItem: true,
            buttonType: item.buttonType,
            disableRadioButtons: isReservedId
        });
    }

    async deleteItem(e) {
        e.preventDefault();
        const array = this.state.localItemsList;
        const index = array[e.target.value];
        const response = await WarningMessage.fire({
            title: 'Delete?',
            text: 'Are you sure you want to do this? You will no longer be able to use this anymore',
            confirmButtonText: 'Delete'
        });
        if (response.value) {
            const updatedItemsList = this.state.localItemsList.filter((item, idx) => idx !== parseInt(e.target.value));
            this.setState({ localItemsList: updatedItemsList, hasUnsavedChanges: true }); // Set hasUnsavedChanges to true when local changes are made
            Toast.fire({
                title: 'Deleted Locally!'
            });
        }
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            itemName: "",
            itemLink: "",
            icon: '',
            itemToEdit: null,
            editingItem: false,
            buttonType: 'isWebsiteLink',
            disableRadioButtons: false
        });
    }

    async changeOrder(item, direction, index) {
        let itemsListCopyArray = this.state.localItemsList || [];
        let originalOrder = item.order || index;
        const itemId = item.id || item.key;

        // Check if the order needs to be changed
        if ((originalOrder === 1 && direction === 1) || (originalOrder === itemsListCopyArray.length && direction === -1)) {
            return;
        }

        let newOrder = originalOrder - direction;
        const itemListCopyObject = {};

        // Update orders based on direction
        for (let i in itemsListCopyArray) {
            let currentLocationInArray = parseInt(i) + 1;
            let itemIterated = itemsListCopyArray[i] || {};
            let itemIteratedId = itemIterated.id || itemIterated.key;

            if (newOrder === itemIterated.order) {
                itemIterated.order = originalOrder;
            } else if (itemId === itemIteratedId) {
                itemIterated.order = newOrder;
            } else if (!itemIterated.order && currentLocationInArray === newOrder) {
                itemIterated.order = originalOrder;
            } else if (!itemIterated.order) {
                itemIterated.order = currentLocationInArray;
            }

            itemListCopyObject[itemIteratedId] = itemIterated;
            itemListCopyObject[itemIteratedId]['key'] = null;
            itemListCopyObject[itemIteratedId]['id'] = itemIteratedId;
        }

        // Reorder items if there are duplicates or if the ordering doesn't start from 1
        let orderSet = new Set();
        for (let item of itemsListCopyArray) {
            if (item.order) {
                orderSet.add(item.order);
            }
        }

        if (orderSet.size !== itemsListCopyArray.length || !orderSet.has(1)) {
            itemsListCopyArray.sort((a, b) => (a.order || 0) - (b.order || 0));
            itemsListCopyArray.forEach((item, idx) => {
                item.order = idx + 1;
                itemListCopyObject[item.id || item.key].order = idx + 1;
            });
        }

        this.setState({ localItemsList: itemsListCopyArray, hasUnsavedChanges: true }); // Set hasUnsavedChanges to true when local changes are made
    }

    saveLocalStateToDatabase() {
        const { localItemsList } = this.state;

        if (localItemsList.length === 0) {
            ErrorMessage.fire({
                title: 'Local state is empty!',
                text: 'Nothing to save to the database.'
            });
            return;
        }

        // Create an object for items to be updated in the database
        const itemListCopyObject = {};
        localItemsList.forEach(item => {
            itemListCopyObject[item.id || item.key] = { ...item, key: null, id: item.id || item.key };
        });

        // Retrieve the existing items in the database
        appDatabasePrimaryFunctions.ref('sideMenuOptions').once('value')
            .then(snapshot => {
                const existingItems = snapshot.val() || {};
                const itemsToDelete = [];

                // Identify items that need to be deleted (those in the database but not in localItemsList)
                Object.keys(existingItems).forEach(id => {
                    if (!itemListCopyObject[id]) {
                        itemsToDelete.push(id);
                    }
                });

                // Remove items that are not present in localItemsList
                const deletePromises = itemsToDelete.map(id =>
                    appDatabasePrimaryFunctions.ref(`sideMenuOptions/${id}`).remove()
                );

                // Update the database with the new items and handle deletions
                return Promise.all(deletePromises)
                    .then(() => appDatabasePrimaryFunctions.ref('sideMenuOptions').update(itemListCopyObject));
            })
            .then(() => {
                Toast.fire({
                    title: 'Saved Successfully!'
                });
                this.setState({ hasUnsavedChanges: false }); // Set hasUnsavedChanges to false when changes are saved
            })
            .catch(err => {
                ErrorMessage.fire({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists, try logging out and logging back in.'
                });
                console.log(err);
            });
    }

    render() {
        const { loading, disableRadioButtons = false, localItemsList, hasUnsavedChanges } = this.state;

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="card">
                        <div className="card-body">
                            <p className="admin-header-text" style={{ marginBottom: 0 }}>Side Menu Buttons</p>
                            <p className="admin-subheader-text">These items will make up the side menu of your app</p>
                            <button className="btn btn-primary btn-lg create-prize-button" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.toggle}>Add Item</button>
                            <button className="btn btn-success btn-lg" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.saveLocalStateToDatabase}>
                                Save
                            </button>
                            {hasUnsavedChanges && (
                                <p style={{ color: 'red', marginTop: 10 }}>You have unsaved changes</p>
                            )}
                            <div className="row">
                                <div className="col-md-8">
                                    <ul className="list-group admin-list" style={{ marginTop: 20, color: 'black' }}>
                                        {
                                            localItemsList.sort((a, b) => a.order > b.order ? 1 : -1).map((item, i) => {
                                                let orderNumber = item.order || i + 1;
                                                return (
                                                    <li key={i} className="list-group-item">
                                                        <div className="d-flex between align-items-center justify-content-between">
                                                            <div>
                                                                <p style={{ marginTop: 5 }}>{item.header}</p>
                                                                {item.buttonType === 'isWebsiteLink' && <p style={{ marginTop: 5 }}>{item.link}</p>}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ marginLeft: 40, fontSize: 40 }}>
                                                                    {localItemsList.length > 1 && (
                                                                        <div>
                                                                            <span style={{ display: orderNumber <= 1 && "none", cursor: "pointer" }} onClick={() => this.changeOrder(item, 1, i + 1)}>
                                                                                ⬆️
                                                                            </span>
                                                                            <span style={{ display: localItemsList.length <= i + 1 && "none", cursor: "pointer", marginLeft: 30 }} onClick={() => this.changeOrder(item, -1, i + 1)}>
                                                                                ⬇️
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{ marginLeft: 40 }}>
                                                                    <button className="btn btn-primary btn-lg edit-button" style={{ marginRight: 5 }} onClick={this.editItem.bind(this)} value={i}><span className="fa fa-ellipsis-v" /> Edit</button>
                                                                    {this.state.reservedIds.indexOf(item.id) === -1 &&
                                                                        <button className="btn btn-primary btn-lg delete-button" onClick={this.deleteItem.bind(this)} value={i}><span className="fa fa-trash-o" /> Delete</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <SideMenuPreview backgroundImage={this.state.backgroundImage} items={localItemsList} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ width: '90%' }} id="myModal">
                    <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                    <ModalHeader toggle={this.toggle}>Add Item</ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                                    <div className="form-group">
                                        <label htmlFor="itemName">{disableRadioButtons ? "Recommended Name (changing this may impact the apps ability of getting approved):" : "Name" }</label>
                                        <input id="itemName" name="itemName" type="text" className="form-control" value={this.state.itemName} onChange={this.handleLocalChange} placeholder="Name" />
                                    </div>
                                    <div className="row">
                                        {process.env.REACT_APP_HAS_PRIZES === "true" && (
                                            <>
                                                <div className="col-12 mb-3">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input
                                                                    id="isWebsiteLink"
                                                                    name="buttonType"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="isWebsiteLink"
                                                                    checked={this.state.buttonType === "isWebsiteLink"}
                                                                    onChange={this.handleLocalChange}
                                                                    disabled={disableRadioButtons}
                                                                />
                                                                <label className="form-check-label" htmlFor="isWebsiteLink">
                                                                    Website Link
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input
                                                                    id="isSupportLink"
                                                                    name="buttonType"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="isSupportLink"
                                                                    checked={this.state.buttonType === "isSupportLink"}
                                                                    onChange={this.handleLocalChange}
                                                                    disabled={disableRadioButtons}
                                                                />
                                                                <label className="form-check-label" htmlFor="isSupportLink">
                                                                    Open Support
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input
                                                                    id="isEditUserInfo"
                                                                    name="buttonType"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="isEditUserInfo"
                                                                    checked={this.state.buttonType === "isEditUserInfo"}
                                                                    onChange={this.handleLocalChange}
                                                                    disabled={disableRadioButtons}
                                                                />
                                                                <label className="form-check-label" htmlFor="isEditUserInfo">
                                                                    Edit User Info
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input
                                                                    id="isSectionTitle"
                                                                    name="buttonType"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="isSectionTitle"
                                                                    checked={this.state.buttonType === "isSectionTitle"}
                                                                    onChange={this.handleLocalChange}
                                                                    disabled={disableRadioButtons}
                                                                />
                                                                <label className="form-check-label" htmlFor="isSectionTitle">
                                                                    Is Section Title
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input
                                                                    id="isLogOut"
                                                                    name="buttonType"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value="isLogOut"
                                                                    checked={this.state.buttonType === "isLogOut"}
                                                                    onChange={this.handleLocalChange}
                                                                    disabled={disableRadioButtons}
                                                                />
                                                                <label className="form-check-label" htmlFor="isLogOut">
                                                                    Is Log Out
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {process.env.REACT_APP_HAS_TICKETMASTER === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="logOutTicketMaster"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="logOutTicketMaster"
                                                                        checked={this.state.buttonType === "logOutTicketMaster"}
                                                                        onChange={this.handleLocalChange}
                                                                        disabled={disableRadioButtons}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="logOutTicketMaster">
                                                                        Logout Ticketmaster SDK Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {process.env.REACT_APP_HAS_TICKETMASTER === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isTicketMaster"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isTicketMaster"
                                                                        checked={this.state.buttonType === "isTicketMaster"}
                                                                        onChange={this.handleLocalChange}
                                                                        disabled={disableRadioButtons}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isTicketMaster">
                                                                        Ticketmaster SDK Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {process.env.REACT_APP_HAS_PLAYER_PICK === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isPlayerPick"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isPlayerPick"
                                                                        checked={this.state.buttonType === "isPlayerPick"}
                                                                        onChange={this.handleLocalChange}
                                                                        disabled={disableRadioButtons}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isPlayerPick">
                                                                        Player Pick Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <div className="row">
                                                        {process.env.REACT_APP_HAS_BADGES === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isBadges"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isBadges"
                                                                        checked={this.state.buttonType === "isBadges"}
                                                                        onChange={this.handleLocalChange}
                                                                        disabled={disableRadioButtons}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isBadges">
                                                                        Badges Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {process.env.REACT_APP_HAS_CHECK_IN === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isCheckIn"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isCheckIn"
                                                                        checked={this.state.buttonType === "isCheckIn"}
                                                                        onChange={this.handleLocalChange}
                                                                        disabled={disableRadioButtons}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isCheckIn">
                                                                        Check In Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {process.env.REACT_APP_HAS_PRIZES === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isPrizes"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isPrizes"
                                                                        checked={this.state.buttonType === "isPrizes"}
                                                                        onChange={this.handleLocalChange}
                                                                        disabled={disableRadioButtons}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isPrizes">
                                                                        Reward Store Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <div className="row">
                                                        {process.env.REACT_APP_HAS_RADIO === "true" && (
                                                            <div className="col-md-4">
                                                                <div className="form-check">
                                                                    <input
                                                                        id="isRadio"
                                                                        name="buttonType"
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value="isRadio"
                                                                        checked={this.state.buttonType === "isRadio"}
                                                                        onChange={this.handleLocalChange}
                                                                        disabled={disableRadioButtons}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="isRadio">
                                                                        Radio Button
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {this.state.buttonType === "isWebsiteLink" && (
                                            <div className="col-12 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="itemLink">Link:</label>
                                                    <input
                                                        id="itemLink"
                                                        name="itemLink"
                                                        type="url"
                                                        className="form-control"
                                                        value={this.state.itemLink}
                                                        onChange={this.handleLocalChange}
                                                        placeholder="https://linktogoto.com"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group text-center">
                                        <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SetUpSideMenu;
